import { Transaction } from 'main/models/Transaction';
import {
  paginationSelector,
  useTransactionPagination,
} from 'modules/pagination';
import React from 'react';
import { useSelector } from 'react-redux';
import { TransactionDetails } from './TransactionDetails';

interface Props {
  transactions: Transaction[];
  price?: number;
  showInlineInternalTransactions?: boolean;
  onSelectInputDataType?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  showInputDataAsHex?: boolean;
  searchQuery: string;
  numberOfResults: number;
}

export const TransactionsList: React.FC<Props> = ({
  transactions,
  price,
  onSelectInputDataType,
  showInputDataAsHex,
  searchQuery,
  numberOfResults,
}) => {
  const { resultsPerPage } = useSelector(paginationSelector.getState);
  const { currentPage } = useTransactionPagination(numberOfResults);

  return (
    <>
      {transactions
        .map((transaction: Transaction, index: number) => (
          <TransactionDetails
            index={index}
            transaction={transaction}
            price={price}
            onSelectInputDataType={onSelectInputDataType}
            showInputDataAsHex={showInputDataAsHex}
          />
        ))
        .slice(
          0 + resultsPerPage * (Number(currentPage) - 1),
          resultsPerPage * Number(currentPage)
        )}
    </>
  );
};
