enum GeneralActions {
    Request = 'transaction/REQUEST',
    Error = 'transaction/ERROR'
}

enum GetActions {
    GetTransaction = 'transaction/GET_TRANSACTION',
    GetLatestTransactions = 'transaction/GET_LATEST_TRANSACTIONS'
}

export const TransactionActionTypes = {
    ...GeneralActions,
    ...GetActions
};
