import {
    CHART_DEFAULT_OPTIONS,
    CHART_STYLE_LINE,
    CHART_TYPE_LINE
} from '../constants';

import { SimpleResponse } from 'modules/coingecko';

import { CURRENCY_TOLAR, CURRENCY_ARRAY } from 'main/constants';

import CoinGecko from 'coingecko-api';

const client = new CoinGecko();

const getRealtimeScaleConfig = (currency: string) => ({
    scales: {
        xAxes: [
            {
                ticks: {
                    display: false
                },
                gridLines: {
                    display: false
                },
                type: 'realtime',
                realtime: {
                    pause: false,
                    color: 'rgba(255,255,255,0.08)',
                    duration: 600000,
                    refresh: 2000,
                    delay: 4000,
                    onRefresh: (chart: any) => {
                        client.simple
                            .price({
                                ids: [CURRENCY_TOLAR],
                                vs_currencies: CURRENCY_ARRAY
                            })
                            .then((result: SimpleResponse) => {
                                if (chart && chart.config) {
                                    const point: Chart.ChartPoint = {
                                        x: Date.now(),
                                        y: result.data.tolar[currency]
                                    };
                                    chart.config.data.datasets[0].data.push(
                                        point
                                    );
                                }
                            })
                            .catch((error?: string) => {
                                console.log(error);
                            });
                    }
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    display: false,
                    maxTicksLimit: 3
                },
                gridLines: {
                    color: 'rgba(255,255,255,0.08)',
                    lineWidth: 1,
                    drawBorder: false
                }
            }
        ]
    }
});

export const getRealtimeChartConfig = (
    backgroundColor: CanvasGradient | null,
    currency: string
) => {
    console.log('backgroundColor', backgroundColor);
    const newConfig: Chart.ChartConfiguration = {
        type: CHART_TYPE_LINE,
        options: {
            ...CHART_DEFAULT_OPTIONS,
            ...getRealtimeScaleConfig(currency)
        },
        data: {
            datasets: [
                {
                    ...CHART_STYLE_LINE,
                    ...(backgroundColor ? { backgroundColor } : {}),
                    data: []
                }
            ]
        }
    };

    return newConfig;
};
