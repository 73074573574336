import {
    CHART_DEFAULT_OPTIONS,
    CHART_STYLE_LINE,
    CHART_TYPE_LINE
} from '../constants';
import { GraphData } from '../models';

export const getStaticChartConfig = (
    backgroundColor: CanvasGradient | null,
    data: Array<GraphData>
) => ({
    type: CHART_TYPE_LINE,
    options: CHART_DEFAULT_OPTIONS,
    data: {
        labels: data.map((item: GraphData) => `${item.timestamp}`),
        datasets: [
            {
                ...CHART_STYLE_LINE,
                ...(backgroundColor ? { backgroundColor } : {}),
                data: data.map((item: GraphData) => item.price)
            }
        ]
    }
});
