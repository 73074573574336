import { createAction, ActionUnion } from 'modules/redux-store';
import { PaginationActionTypes } from './types';

const SetActions = {
    setResultsPerPage: (resultsPerPage: number) =>
        createAction(PaginationActionTypes.SetResultsPerPage, {
            resultsPerPage,
        }),
};

export const PaginationActions = {
    ...SetActions,
};

export type PaginationAction = ActionUnion<typeof PaginationActions>;
