import { CoingeckoAction } from './actions';
import { CoingeckoActionTypes } from './types';

export interface CoingeckoState {
    history: Array<any>;
    percentage: { [key: string]: number };
    isLoading: boolean;
    isLoadingHistory: boolean;
    isReloadingData: boolean;
    price: { [key: string]: number };
    market_cap: { [key: string]: number };
    transactions: { [key: string]: number };
}

const INITIAL_STATE: CoingeckoState = {
    percentage: {},
    history: [],
    isLoading: true,
    isReloadingData: false,
    isLoadingHistory: true,
    price: {},
    market_cap: {},
    transactions: {}
};

export const coingeckoReducer = (
    state: CoingeckoState = INITIAL_STATE,
    action: CoingeckoAction
) => {
    switch (action.type) {
        case CoingeckoActionTypes.Request:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case CoingeckoActionTypes.Error:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        case CoingeckoActionTypes.GetTolarChartHistory:
            return {
                ...state,
                ...action.payload,
                isLoadingHistory: false
            };
        case CoingeckoActionTypes.GetTolarValue:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        default:
            return { ...state };
    }
};
