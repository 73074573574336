import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  DateTime,
  FromTo,
  ShortenedText,
  Table,
  Toggle,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'main/components';
import { Transaction } from 'main/models';
import { Skeleton } from './Skeleton';
import { bufferToRawNumber, getBuffer, tolarValue } from 'main/util';

interface Props {
  data: Array<Transaction>;
  isLoading?: boolean;
  isActive?: boolean;
}

export const BlockTransactionList: React.FC<Props> = ({
  data,
  isLoading,
  isActive,
}) => {
  return (
    <Toggle
      title="All Block Transactions"
      isActive={isActive}
      dataLength={data.length}
    >
      <Table className="table table--med table-responsive--desktop s-bottom--xlrg t-nowrap">
        <TableHead>
          <span className="t-small opacity-50">Hash</span>
          <span className="t-small opacity-50">Time</span>
          <span className="t-small opacity-50">From / To</span>
          <span className="t-small opacity-50">Gas used</span>
          <span className="t-small opacity-50">Gas price</span>
          <span className="t-small opacity-50">Value</span>
        </TableHead>
        <TableBody>
          {isLoading ? (
            [...Array(8)].map((_, index: number) => (
              <Skeleton key={`skeleton-blockTransactionList-${index}`} />
            ))
          ) : !data || data.length === 0 ? (
            <TableRow key="empty">
              <TableCell label="Hash">No data available!</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          ) : (
            data.map(
              ({
                hash,
                transaction_timestamp,
                sender_address,
                receiver_address,
                gas_used,
                gas_price,
                value,
              }: Transaction) => (
                <TableRow key={hash}>
                  <TableCell label="Hash">
                    <Link className="t-small" to={`/transaction/${hash}`}>
                      <ShortenedText text={hash} />
                    </Link>
                  </TableCell>
                  <TableCell label="Time">
                    <DateTime
                      className="t-tiny"
                      date={new Date(parseInt(transaction_timestamp))}
                    />
                  </TableCell>
                  <TableCell label="From/To">
                    <FromTo
                      inline
                      from={sender_address}
                      to={receiver_address}
                    />
                  </TableCell>
                  <TableCell label="Gas used">
                    <span className="t-small opacity-60 t-tiny">
                      {`${
                        gas_used ? bufferToRawNumber(getBuffer(gas_used)) : ''
                      }`}
                    </span>
                  </TableCell>
                  <TableCell label="Gas price">
                    <span className="t-small opacity-60 t-tiny">
                      {gas_price}
                    </span>
                  </TableCell>
                  <TableCell label="Value">
                    <span>Tol {tolarValue(value)}</span>
                  </TableCell>
                </TableRow>
              )
            )
          )}
        </TableBody>
      </Table>
    </Toggle>
  );
};
