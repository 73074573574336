import * as React from 'react';

interface Props {
  date: Date;
  className?: string;
  withoutClass?: boolean;
}

export class DateTime extends React.Component<Props> {
  extractDataFromDate = (date: Date) => ({
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  });

  formatDateTime = (date: Date) => {
    const { hours, minutes, seconds, day, month, year } =
      this.extractDataFromDate(date);
    const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;
    const secondsFormatted = seconds < 10 ? '0' + seconds : seconds;
    const dateFormatted = `${day}.${month}.${year}`;
    const timeFormatted = `${hours}:${minutesFormatted}:${secondsFormatted}`;
    return `${dateFormatted}@${timeFormatted}`;
  };

  render() {
    return (
      <div
        className={`${this.props.withoutClass ? '' : 't-small opacity-50 '}${
          this.props.className || ''
        }`}
      >
        {this.formatDateTime(this.props.date)}
      </div>
    );
  }
}
