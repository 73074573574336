import { useTransactionPagination } from '../hooks';
import React from 'react';
import { paginationSelector } from '../redux';
import { useSelector } from 'react-redux';

interface Props {
  numberOfResults: number;
}

export const SearchResultsPagination: React.FC<Props> = ({
  numberOfResults,
}) => {
  const { resultsPerPage } = useSelector(paginationSelector.getState);
  const {
    maxPages,
    onNumberChange,
    onArrowChange,
    currentPage,
    maxNumber,
    sliceNumbers,
    handleResultsPerPageChange,
  } = useTransactionPagination(numberOfResults);

  return (
    <div className="pagination">
      <div className="pagination__numbers">
        <button
          className="pagination__button pagination__button--prev"
          disabled={currentPage === '1'}
          onClick={() => onArrowChange('previous')}
        />
        <button
          className="pagination__number"
          disabled={currentPage === '1'}
          onClick={() => onNumberChange(1)}
        >
          1
        </button>
        {maxPages > 5 && Number(currentPage) > 3 && (
          <span className="pagination__dots">...</span>
        )}
        {maxPages > 1 &&
          [...Array(maxPages - 2)]
            .map((_, i) => (
              <button
                className="pagination__number"
                disabled={currentPage === (i + 2).toString()}
                onClick={() => onNumberChange(i + 2)}
                key={i + 2}
              >
                {i + 2}
              </button>
            ))
            .slice(
              sliceNumbers().firstSliceNumber,
              sliceNumbers().lastSliceNumber
            )}
        {maxPages > 5 && Number(currentPage) < maxPages - 2 && (
          <span className="pagination__dots">...</span>
        )}
        {maxPages > 1 && (
          <button
            className="pagination__number"
            disabled={currentPage === maxPages.toString()}
            onClick={() => onNumberChange(maxPages)}
          >
            {maxPages}
          </button>
        )}
        <button
          className="pagination__button pagination__button--next"
          disabled={currentPage === maxPages.toString()}
          onClick={() => onArrowChange('next')}
        />
      </div>
      <div className="pagination__results">
        {1 + (Number(currentPage) - 1) * resultsPerPage}-
        {maxNumber(Number(currentPage) * resultsPerPage)} of {numberOfResults}
      </div>
      <div>
        Results per page
        <select
          id="resultsPerPage"
          className="pagination__select"
          value={resultsPerPage}
          onChange={handleResultsPerPageChange}
        >
          {[...Array(10)].map((_, i) => (
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
