import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import anime from 'animejs';
import {
  Search,
  SearchResults,
  Summary,
  LatestBlocks,
  LatestTransactions,
  Stats,
  TransactionThunks,
  CoingeckoThunks,
} from 'modules';
import { connect } from 'react-redux';
import { BlockThunks } from 'modules/block-details/redux';
import { compose } from 'redux';
import { Block, Transaction } from 'main/models';
import { ReduxDispatch, ApplicationState } from 'modules/redux-store';
import { SearchThunks } from 'modules/search';
import { QueryResults } from 'main/models/QueryResults';

interface State {
  searchValue?: string;
  isSearchValid: boolean;
}

interface DispatchProps {
  getLatestBlocks: () => void;
  getLatestTransactions: () => void;
  getTolarValue: () => void;
  search: (searchString: string) => void;
}

interface ReduxProps {
  latestBlocks: Block[];
  latestTransactions: Transaction[];
  searchResults?: QueryResults<any>;
  price?: number;

  isLoadingBlocks: boolean;
  isLoadingTransactions: boolean;
  isLoadingSearch: boolean;

  blockError?: string;
  transactionError?: string;
  searchError?: string;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

class Homepage extends React.Component<Props, State> {
  state = {
    searchValue: '',
    isSearchValid: false,
  };

  private refreshTimeout: NodeJS.Timer | undefined;

  componentDidMount() {
    /* Animate splash header of the section to have a nice fade in */
    anime({
      targets: 'main',
      opacity: [0, 1],
      duration: 300,
      easing: 'easeOutQuad',
    });

    this.setState(
      {
        searchValue: this.getSearchQuery() || '',
        isSearchValid: true,
      },
      () => {
        this.props.getTolarValue();
        this.handleSearchSubmit();
      }
    );

    this.startRefreshingData();
  }

  componentWillUnmount() {
    this.stopRefreshingData();
  }

  componentWillReceiveProps(_: Props) {
    if (this.getSearchQuery()) {
      this.stopRefreshingData();
    } else {
      this.startRefreshingData();
    }
  }

  handleSearchInputChange = (
    _: string,
    value: string,
    isValid: boolean | undefined
  ) => {
    this.setState({ searchValue: value, isSearchValid: !!isValid });
  };

  handleSearchSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    const { searchValue, isSearchValid } = this.state;
    if (isSearchValid && searchValue) {
      this.props.search(searchValue);
      this.props.history.push({
        search: `?query=${searchValue}&page=1`,
      });
    }
  };

  getSearchQuery = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('query');
  };

  refreshData = () => {
    this.props.getLatestBlocks();
    this.props.getLatestTransactions();
  };

  startRefreshingData = () => {
    if (!this.getSearchQuery() && !this.refreshTimeout) {
      this.refreshTimeout = setInterval(this.refreshData, 5000);
    }
  };

  stopRefreshingData = () => {
    if (this.refreshTimeout) {
      clearInterval(this.refreshTimeout);
      this.refreshTimeout = undefined;
    }
  };

  render() {
    const { searchValue } = this.state;
    const { getSearchQuery, handleSearchInputChange, handleSearchSubmit } =
      this;

    const {
      latestBlocks,
      latestTransactions,
      searchResults,
      price,
      isLoadingBlocks,
      isLoadingTransactions,
      isLoadingSearch,
      blockError,
      transactionError,
      searchError,
    } = this.props;

    const searchSubmittedValue = getSearchQuery();

    return (
      <section className="wrapper formatted home">
        <Helmet>
          <title>Tolar Explorer</title>
        </Helmet>
        <Search
          value={searchValue}
          onValueChange={handleSearchInputChange}
          onValueSubmit={handleSearchSubmit}
        />
        {searchSubmittedValue ? (
          <SearchResults
            searchResult={searchResults}
            searchError={searchError}
            isLoading={isLoadingSearch}
            searchQuery={searchSubmittedValue}
            price={price}
          />
        ) : (
          <React.Fragment>
            <article className="home__wrapper">
              <div className="home__col--aside">
                <Summary className="home__summary" />
              </div>
              <div className="home__col--main">
                <Stats />
              </div>
            </article>

            <article className="home__wrapper">
              <div className="home__col--aside">
                <LatestBlocks
                  isLoading={isLoadingBlocks}
                  data={latestBlocks}
                  error={blockError}
                />
              </div>
              <div className="home__col--main">
                <LatestTransactions
                  isLoading={isLoadingTransactions}
                  data={latestTransactions}
                  error={transactionError}
                />
              </div>
            </article>
          </React.Fragment>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
  return {
    latestBlocks: state.block.blocks,
    latestTransactions: state.transaction.transactions,
    searchResults: state.search.searchResults,
    price: state.coingecko.price[state.settings.currency],

    isLoadingBlocks: state.block.isLoading,
    isLoadingTransactions: state.transaction.isLoading,
    isLoadingSearch: state.search.isLoading,

    blockError: state.block.error,
    transactionError: state.transaction.error,
    searchError: state.search.error,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch): DispatchProps => ({
  getLatestBlocks: () => dispatch(BlockThunks.getLatestBlocks()),
  getLatestTransactions: () =>
    dispatch(TransactionThunks.getLatestTransactions()),
  search: (searchString: string) =>
    dispatch(SearchThunks.searchBlocksAndTransactions(searchString)),
  getTolarValue: () => dispatch(CoingeckoThunks.getCurrentValue()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Homepage);
