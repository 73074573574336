import { createAction, ActionUnion } from 'modules/redux-store';
import { TransactionActionTypes } from './types';
import { Transaction } from 'main/models';

const GeneralActions = {
    request: () => createAction(TransactionActionTypes.Request),
    error: (error?: string) =>
        createAction(TransactionActionTypes.Error, { error })
};

const GetActions = {
    getTransaction: (transaction: Transaction) =>
        createAction(TransactionActionTypes.GetTransaction, { transaction }),
    getLatestTransactions: (transactions: Transaction[]) =>
        createAction(TransactionActionTypes.GetLatestTransactions, {
            transactions
        })
};

export const TransactionActions = {
    ...GeneralActions,
    ...GetActions
};

export type TransactionAction = ActionUnion<typeof TransactionActions>;
