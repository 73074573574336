import * as React from 'react';
import { Tab } from 'main/models';

interface Props {
  tabList: Array<Tab>;
}

interface State {
  activeTabId: string;
}

export class Tabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTabId: props.tabList[0].id
    };
  }

  handleTabClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { value } = event.currentTarget;
    this.setState({
      activeTabId: value
    });
  };

  getTabsNav = () => {
    const { tabList } = this.props;
    return tabList.map(item => (
      <button
        className={`btn btn--ghost btn--med s-right--lrg ${
          item.id === this.state.activeTabId ? 'btn--ghost--active' : ''
        }`}
        onClick={this.handleTabClick}
        key={item.id}
        value={item.id}
      >
        {item.label}
      </button>
    ));
  };

  getActiveTabContents = () => {
    const { tabList } = this.props;
    const { activeTabId } = this.state;
    const activeTab = tabList.find(item => item.id === activeTabId);
    return activeTab && activeTab.content;
  };

  render() {
    const tabNavigation = this.getTabsNav();
    const activeTabContent = this.getActiveTabContents();

    if (!tabNavigation || !activeTabContent) return null;

    return (
      <React.Fragment>
        <nav>{tabNavigation}</nav>
        {activeTabContent}
      </React.Fragment>
    );
  }
}
