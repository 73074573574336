import * as React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Table: React.FC<Props> = ({ children, ...rest }) => (
  <table className="table" {...rest}>
    {children}
  </table>
);
