import * as React from 'react';
import {
  ContentWithDate,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from 'main/components';
import { Link } from 'react-router-dom';
import { Skeleton } from './Skeleton';
import { Block } from 'main/models';

interface Props {
  data: Block[];
  isLoading?: boolean;
  error?: string;
}

export const LatestBlocks: React.FC<Props> = ({ data, isLoading, error }) => {
  const mapData = ({ block_index, confirmation_timestamp }: Block) => (
    <TableRow key={block_index}>
      <TableCell>
        <ContentWithDate date={new Date(confirmation_timestamp)}>
          <Link to={`/block/${block_index}`}>{`#${block_index}`}</Link>
        </ContentWithDate>
      </TableCell>
      {/* <TableCell className="t-right">Tol NaN</TableCell> */}
    </TableRow>
  );

  const getSkeleton = (_: any, index: number) => (
    <Skeleton key={`latest-block-${index}`} />
  );

  return (
    <div>
      <h2 className="s-bottom--zero">
        <strong>Latest</strong> Blocks
      </h2>
      <Table className="table table--align-top">
        <TableBody>
          {!error ? (
            isLoading ? (
              [...Array(5)].map(getSkeleton)
            ) : (
              data.map(mapData)
            )
          ) : (
            <TableRow key="1">
              <TableCell>
                <div>{error}</div>
              </TableCell>
              {/* <TableCell className="t-right">Tol NaN</TableCell> */}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
