import { Currency } from 'main/models';

export const currency_USD: Currency = {
    label: 'USD',
    value: 'usd'
};

export const currency_EUR: Currency = {
    label: 'EUR',
    value: 'eur'
};

export const currencies: Array<Currency> = [currency_USD, currency_EUR];
