import * as React from 'react';
import { DateTime } from 'main/components';

interface Props {
  children: React.ReactNode;
  date: Date;
}

export const ContentWithDate: React.FC<Props> = ({ date, children }) => (
  <React.Fragment>
    <div>{children}</div>
    <DateTime date={date} />
  </React.Fragment>
);
