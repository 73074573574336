import * as React from 'react';
import { Dataset } from 'main/models';

interface Props {
  data: Array<Dataset>;
  activeDataId: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const DataControls: React.FC<Props> = ({
  data,
  activeDataId,
  onClick
}) => (
  <div>
    {data.map(item => (
      <button
        className={`btn btn--ghost btn--med s-right--lrg ${
          item.id === activeDataId ? 'btn--ghost--active' : ''
        }`}
        onClick={onClick}
        key={item.id}
        value={item.id}
      >
        {item.label}
      </button>
    ))}
  </div>
);
