import * as React from 'react';
import { SkeletonLoader, TableRow, TableCell } from 'main/components';

export const Skeleton: React.FC = () => (
  <TableRow>
    <TableCell>
      <SkeletonLoader />
      <SkeletonLoader className="skeletonLoader--tny s-top--sml" />
    </TableCell>
    <TableCell>
      <SkeletonLoader />
    </TableCell>
  </TableRow>
);
