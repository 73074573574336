import { createAction, ActionUnion } from 'modules/redux-store';
import { SearchActionTypes } from './types';
import { QueryResults } from 'main/models/QueryResults';

export const SearchActions = {
    request: () => createAction(SearchActionTypes.Request),
    result: (searchResults: QueryResults<any>) =>
        createAction(SearchActionTypes.Result, { searchResults }),
    error: (error?: string) => createAction(SearchActionTypes.Error, { error })
};

export type SearchAction = ActionUnion<typeof SearchActions>;
