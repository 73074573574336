import * as React from 'react';
import { ReduxDispatch } from 'modules/redux-store';
import { connect } from 'react-redux';
import { Currency } from 'main/models';
import { SettingsActions } from '../redux';
import { ApplicationState } from 'modules/redux-store';
import { currencies } from '../constants';

interface ReduxProps {
  currency: string;
}

interface DispatchProps {
  setSettingsCurrency: (currency: string) => void;
}

type Props = DispatchProps & ReduxProps;

class CurrencySwitcher extends React.Component<Props> {
  getOption = ({ label, value }: Currency) => (
    <option key={`option-currency-${value}`} value={value}>
      {label}
    </option>
  );

  handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const { setSettingsCurrency } = this.props;
    setSettingsCurrency(value);
  };

  render() {
    const { currency } = this.props;

    return (
      <select
        onChange={this.handleCurrencyChange}
        className="input input--select"
        value={currency}
      >
        {currencies.map(this.getOption)}
      </select>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
  return { currency: state.settings.currency };
};

const mapDispatchToProps = (dispatch: ReduxDispatch): DispatchProps => ({
  setSettingsCurrency: currency =>
    dispatch(SettingsActions.setSettingsCurrency(currency))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencySwitcher);
