import * as React from 'react';

interface Props {
  name: string;
  className: string;
  value: string;
  compareValue?: string;
  placeholder?: string;
  label?: string;
  isValid: boolean | undefined;
  invalidText?: string;
  emptyText?: string;
  optional?: boolean;
  disabled?: boolean;
  validation?: 'password' | 'comparable' | 'number' | 'email';
  decimals?: number;
  minValue?: number;
  maxValue?: number;
  type?: 'email' | 'number' | 'text' | 'password';
  onValueChange: (
    name: string,
    value: string,
    isValid: boolean | undefined
  ) => void;
}

export class ValidatedInput extends React.Component<Props> {
  private readonly EMAIL_REGEX: RegExp = /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private readonly PASSWORD_REGEX: RegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,24}$/;

  /** This is triggered on change and as such should reset validation status and parse value */
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.currentTarget;

    /**
     * Edit to ValidatedInput
     * Added onchange validation so the user can submit the form by pressing enter without leaving the field in order for validation to run
     */

    const isValid = this.validateInput(value);

    this.props.onValueChange(name, value, isValid);

    /* End edit */
  };

  onDone = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onValueChange } = this.props;
    const { name } = event.currentTarget;
    let { value } = event.currentTarget;

    value = this.formatValue(value);
    const isValid = this.validateInput(value);

    onValueChange(name, value, isValid);
  };

  validateInput = (value: string) => {
    const { optional, validation, compareValue } = this.props;

    // If value isn't set, return false valid state unless input is optional
    if (!value.length) {
      return optional ? undefined : false;
    }

    let isValid: boolean | undefined = true;
    switch (validation) {
      case 'password':
        isValid = this.PASSWORD_REGEX.test(value);
        break;
      case 'email':
        isValid = this.EMAIL_REGEX.test(value);
        break;
      case 'comparable':
        isValid = value === compareValue;
        break;
      case 'number':
        isValid = this.validateNumberValue(value);
        break;
    }

    return isValid;
  };

  validateNumberValue = (value: string) => {
    const { minValue, maxValue } = this.props;
    const numeric = Number(value);

    if (isNaN(numeric)) {
      return false;
    }

    let isValid = true;
    if (minValue) {
      isValid = numeric >= minValue;
    }

    if (maxValue) {
      isValid = numeric <= maxValue;
    }

    return isValid;
  };

  /** Format numeric value to enough decimals */
  formatValue = (value: string) => {
    const { decimals, type } = this.props;

    if (type === 'number') {
      return Number(value).toFixed(decimals || 2);
    }

    return value;
  };

  getInvalidErrorText = () => {
    const { validation, invalidText } = this.props;
    if (invalidText) {
      return invalidText;
    }
    switch (validation) {
      case 'password':
        return 'Password must be 4 - 24 characters long.';
      case 'comparable':
        return "The values you entered don't match.";
      case 'email':
        return 'This is not a valid email address';
      default:
        return "The value you entered isn't right.";
    }
  };

  getEmptyErrorText = () => {
    const { validation, emptyText } = this.props;

    if (emptyText) {
      return emptyText;
    }

    switch (validation) {
      case 'password':
        return 'Password must be at least 4 characters long.';
      case 'comparable':
        return 'This can not be empty.';
      case 'email':
        return "Email can't be left empty";
      default:
        return "This field can't be empty.";
    }
  };

  /** Render error message is isValid state is false, else return nothing */
  renderErrorMessage = () => {
    const { isValid, value } = this.props;

    if (isValid === false) {
      return value.length ? (
        <p className="t-color--warning t-micro s-top--sml">
          {this.getInvalidErrorText()}
        </p>
      ) : (
        <p className="t-color--warning t-micro s-top--sml">
          {this.getEmptyErrorText()}
        </p>
      );
    }

    return null;
  };

  render() {
    const {
      value,
      placeholder,
      disabled,
      name,
      type,
      label,
      className
    } = this.props;

    return (
      <React.Fragment>
        {label && (
          <label htmlFor={name} className={`field__lbl t-eta`}>
            {label}
          </label>
        )}
        <div className={className}>
          <input
            className={`input input--lrg input--text`}
            value={value}
            onBlur={this.onDone}
            onChange={this.onChange}
            onSubmit={this.onDone}
            disabled={disabled}
            name={name}
            type={type || 'text'}
            placeholder={placeholder}
          />
          {this.renderErrorMessage()}
        </div>
      </React.Fragment>
    );
  }
}
