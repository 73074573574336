import CoinGecko from 'coingecko-api';
import { MarketChartResponse, PriceData } from '../models';
import { ReduxDispatch } from 'modules/redux-store';
import { CoingeckoActions } from './actions';
import moment from 'moment';

const client = new CoinGecko();
const now = new Date().getTime();

const getTolarChartHistory = (
    days: number | 'max' | undefined,
    vs_currency: string
) => (dispatch: ReduxDispatch) => {
    const params = { days, vs_currency };
    client.coins
        .fetchMarketChart('tolar', params)
        .then((result: MarketChartResponse) => {
            const prices = result.data.prices;

            // Map all prices to PriceData array and sort by time
            const filteredPrices = !days
                ? prices.filter(p =>
                      moment((p[0] as unknown) as number).isSame(now, 'day')
                  )
                : prices;
            const allPrices: PriceData[] = filteredPrices.map(
                p =>
                    (({
                        timestamp: p[0],
                        price: p[1]
                    } as unknown) as PriceData)
            );

            dispatch(CoingeckoActions.getTolarChartHistory(allPrices));
        })
        .catch((error?: string) => {
            console.log(error);
            dispatch(CoingeckoActions.error(error));
        });
};

const getCurrentValue = () => (dispatch: ReduxDispatch) => {
    client.coins.fetch('tolar', { market_data: true }).then((response: any) => {
        const { market_data } = response.data;
        const {
            price_change_percentage_24h_in_currency,
            current_price,
            market_cap,
            total_volume
        } = market_data;

        const formattedData = {
            percentage: price_change_percentage_24h_in_currency,
            price: current_price,
            market_cap,
            transactions: total_volume
        };

        dispatch(CoingeckoActions.getTolarValue(formattedData));
    });
};

export const CoingeckoThunks = {
    getTolarChartHistory,
    getCurrentValue
};
