import axios, { AxiosRequestConfig } from 'axios';
export class AxiosService {
    private headers: AxiosRequestConfig = {};

    constructor(key?: string, password?: string) {
        if (key && password) {
            this.headers = {
                auth: { username: key, password: password },
            };
        }
    }

    async getRequest<T = any>(
        endpoint: string,
        id?: string,
        suffix?: string
    ): Promise<T | any> {
        const idString = id ? (id.indexOf('?') === 0 ? id : `/${id}`) : '';
        const suffixString = suffix ? `/${suffix}` : '';
        return await axios
            .get(`${endpoint}${idString}${suffixString}`, this.headers)
            .then((res) => (res ? (res.data as T) : res))
            .catch((error) =>
                error ? (error.response ? error.response.data : error) : error
            );
    }

    async postRequest(endpoint: string, postObject: any) {
        return await axios
            .post(`${endpoint}`, postObject, this.headers)
            .then((res) => ({ ...res.data, statusCode: res.status }))
            .catch((error) => ({
                ...error.response.data,
                statusCode: error.response.status,
            }));
    }

    async deleteRequest(endpoint: string, id: string) {
        return await axios
            .delete(`${endpoint}/${id}`, this.headers)
            .then((res) => res.data)
            .catch((error) => error.response.data);
    }
}
