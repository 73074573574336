import { ReduxDispatch } from 'modules/redux-store';
import { SearchActions } from './actions';
import { ApiService } from 'main/services';

const client = new ApiService();

const searchBlocksAndTransactions =
    (searchString: string) => async (dispatch: ReduxDispatch) => {
        if (searchString.length < 5) {
            //TODO return error
        }

        const searchResults = await client.getResults(searchString);
        if (typeof searchResults === 'string') {
            dispatch(SearchActions.error(searchResults));
            return;
        }

        const contractResults = await client.getContract(searchString);
        searchResults.contract = contractResults;

        if (!searchResults.contract || searchResults.contract.notFound) {
            const bytecodeResult = await client.getBytecode(searchString);

            searchResults.contract = {
                deployedBytecode: bytecodeResult.bytecode,
            };
        }

        dispatch(SearchActions.result(searchResults));
    };

export const SearchThunks = {
    searchBlocksAndTransactions,
};
