export const COMPILER_VERSIONS = [
    'v0.8.13+commit.abaa5c0e',
    'v0.8.12+commit.f00d7308',
    'v0.8.11+commit.d7f03943',
    'v0.8.10+commit.fc410830',
    'v0.8.9+commit.e5eed63a',
    'v0.8.8+commit.dddeac2f',
    'v0.8.7+commit.e28d00a7',
    'v0.8.6+commit.11564f7e',
    'v0.8.5+commit.a4f2e591',
    'v0.8.4+commit.c7e474f2',
    'v0.8.3+commit.8d00100c',
    'v0.8.2+commit.661d1103',
    'v0.8.1+commit.df193b15',
    'v0.8.0+commit.c7dfd78e',
    'v0.7.6+commit.7338295f',
    'v0.7.5+commit.eb77ed08',
    'v0.7.4+commit.3f05b770',
    'v0.7.3+commit.9bfce1f6',
    'v0.7.2+commit.51b20bc0',
    'v0.7.1+commit.f4a555be',
    'v0.7.0+commit.9e61f92b',
    'v0.6.12+commit.27d51765',
    'v0.6.11+commit.5ef660b1',
    'v0.6.10+commit.00c0fcaf',
    'v0.6.9+commit.3e3065ac',
    'v0.6.8+commit.0bbfe453',
    'v0.6.7+commit.b8d736ae',
    'v0.6.6+commit.6c089d02',
    'v0.6.5+commit.f956cc89',
    'v0.6.4+commit.1dca32f3',
    'v0.6.3+commit.8dda9521',
    'v0.6.2+commit.bacdbe57',
    'v0.6.1+commit.e6f7d5a4',
    'v0.6.0+commit.26b70077',
    'v0.5.17+commit.d19bba13',
    'v0.5.16+commit.9c3226ce',
    'v0.5.15+commit.6a57276f',
    'v0.5.14+commit.01f1aaa4',
    'v0.5.13+commit.5b0b510c',
    'v0.5.12+commit.7709ece9',
    'v0.5.11+commit.22be8592',
    'v0.5.11+commit.c082d0b4',
    'v0.5.10+commit.5a6ea5b1',
    'v0.5.9+commit.c68bc34e',
    'v0.5.9+commit.e560f70d',
    'v0.5.8+commit.23d335f2',
    'v0.5.7+commit.6da8b019',
    'v0.5.6+commit.b259423e',
    'v0.5.5+commit.47a71e8f',
    'v0.5.4+commit.9549d8ff',
    'v0.5.3+commit.10d17f24',
    'v0.5.2+commit.1df8f40c',
    'v0.5.1+commit.c8a2cb62',
    'v0.5.0+commit.1d4f565a',
    'v0.4.26+commit.4563c3fc',
    'v0.4.25+commit.59dbf8f1',
    'v0.4.24+commit.e67f0147',
    'v0.4.23+commit.124ca40d',
    'v0.4.22+commit.4cb486ee',
    'v0.4.21+commit.dfe3193c',
    'v0.4.20+commit.3155dd80',
    'v0.4.19+commit.c4cbbb05',
    'v0.4.18+commit.9cf6e910',
    'v0.4.17+commit.bdeb9e52',
    'v0.4.16+commit.d7661dd9',
    'v0.4.15+commit.8b45bddb',
    'v0.4.15+commit.bbb8e64f',
    'v0.4.14+commit.c2215d46',
    'v0.4.13+commit.0fb4cb1a',
    'v0.4.12+commit.194ff033',
    'v0.4.11+commit.68ef5810',
    'v0.4.10+commit.f0d539ae',
    'v0.4.9+commit.364da425',
    'v0.4.8+commit.60cc1668',
    'v0.4.7+commit.822622cf',
    'v0.4.6+commit.2dabbdf0',
    'v0.4.5+commit.b318366e',
    'v0.4.4+commit.4633f3de',
    'v0.4.3+commit.2353da71',
    'v0.4.2+commit.af6afb04',
    'v0.4.1+commit.4fc6fc2c',
    'v0.4.0+commit.acd334c9',
    'v0.3.6+commit.3fc68da5',
    'v0.3.5+commit.5f97274a',
    'v0.3.4+commit.7dab8902',
    'v0.3.3+commit.4dc1cb14',
    'v0.3.2+commit.81ae2a78',
    'v0.3.1+commit.c492d9be',
    'v0.3.0+commit.11d67369',
    'v0.2.2+commit.ef92f566',
    'v0.2.1+commit.91a6b35f',
    'v0.2.0+commit.4dc2445e',
    'v0.1.7+commit.b4e666cc',
    'v0.1.6+commit.d41f8b7c',
    'v0.1.5+commit.23865e39',
    'v0.1.4+commit.5f6c3cdf',
    'v0.1.3+commit.028f561d',
    'v0.1.2+commit.d0d36e3',
    'v0.1.1+commit.6ff4cd6',
];
