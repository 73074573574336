import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  SkeletonLoader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  DateTime,
} from 'main/components';
import { Block } from 'main/models';

interface Props {
  data: Block;
  isLoading?: boolean;
  onTransactionClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  onInternalTransactionClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

export const BlockDetails: React.FC<Props> = ({
  data,
  isLoading,
  onTransactionClick,
  onInternalTransactionClick,
}) => {
  const {
    block_index,
    confirmation_timestamp,
    hash,
    previous_block_hash,
    transactions,
    internal_transactions,
  } = data;

  const getData = (data: React.ReactNode): React.ReactNode =>
    isLoading ? <SkeletonLoader className="skeletonLoader--med" /> : data;

  const transactionsAndContracts = (
    <span>
      <Link to="/" onClick={onTransactionClick}>
        {transactions ? transactions.length : '0'} transactions
      </Link>
      {internal_transactions && internal_transactions.length > 0 && (
        <>
          <span className="opacity-80">{` and `}</span>
          <Link to="/" onClick={onInternalTransactionClick}>
            {internal_transactions ? internal_transactions.length : '0'}{' '}
            contract internal transactions
          </Link>
        </>
      )}
      <span className="opacity-80">{` in this block`}</span>
    </span>
  );

  return (
    <Table className="table table--paired table--lrg s-bottom--xlrg t-med">
      <TableBody>
        <TableRow>
          <TableCell as="th" className="t-small opacity-60">
            Block Index
          </TableCell>
          <TableCell>{getData(block_index)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th" className="t-small opacity-60">
            Timestamp
          </TableCell>
          <TableCell className="opacity-80">
            <div className="d-flex">
              <DateTime
                withoutClass={true}
                date={new Date(confirmation_timestamp)}
              />
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th" className="t-small opacity-60">
            Transactions
          </TableCell>
          <TableCell className="opacity-80">
            {getData(transactionsAndContracts)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th" className="t-small opacity-60">
            Hash
          </TableCell>
          <TableCell className="opacity-80">{getData(hash)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell as="th" className="t-small opacity-60">
            Parent Hash
          </TableCell>
          <TableCell className="opacity-80">
            {getData(previous_block_hash)}
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell as="th" className="t-small opacity-60">
            Nonce
          </TableCell>
          <TableCell className="opacity-80">{getData('')}</TableCell>
        </TableRow> */}
      </TableBody>
    </Table>
  );
};
