import * as React from 'react';
import { Footer, Logo } from '.';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import anime from 'animejs';
import { Link } from 'react-router-dom';

class Layout extends React.Component<RouteComponentProps<any>> {
  componentDidMount() {
    this.animateOverlay();
  }

  componentWillUpdate(nextProps: any) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.animateOverlay();
    }
  }

  animateOverlay = () => {
    const overlayAnimation = anime.timeline();
    overlayAnimation.add({
      targets: '.container__content',
      opacity: [0, 1],
      duration: 300,
      easing: 'easeInQuad',
    });
  };

  render() {
    const { children } = this.props;

    return (
      <div className="container">
        <header className="header s-top--med">
          <div className="wrapper s-bottom--xlrg">
            <Link className="d-inline-block logo logo--primary" to="/">
              <h1>
                <Logo />
              </h1>
            </Link>
          </div>
        </header>
        <div className="container__content">
          <main className="container__children">{children}</main>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Layout);
