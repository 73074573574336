import * as React from 'react';

interface Props {
  percentage: number;
}

export const Status: React.FC<Props> = ({ percentage }) => (
  <small className="opacity-50 t-tiny">
    {percentage &&
      `${
        percentage > 0 ? `+${percentage.toFixed(2)}` : percentage.toFixed(2)
      }% since last 24 hours`}
  </small>
);
