export const CURRENCY_TOLAR = 'tolar';

export enum CURRENCY_CODES {
    usd = 'usd',
    eur = 'eur'
}

export enum CURRENCY_INTL {
    usd = 'en-US',
    eur = 'en-EU'
}

export const CURRENCY_ARRAY = [CURRENCY_CODES.usd, CURRENCY_CODES.eur];
