import * as React from 'react';
import { Link } from 'react-router-dom';
import logo from 'main/assets/images/tolar-logo.svg';

export const Footer: React.SFC = () => (
  <footer className="footer s-top--xxlrg s-bottom--xxlrg">
    <div className="footer__wrapper wrapper">
      <div className="footer__col">
        <Link to="/" className="d-inline-block">
          <img src={logo} alt="Tolar logo" />
        </Link>
      </div>
      <div className="footer__col">
        <p className="t-small opacity-30 t-right">
          Copyright &copy; 2018 Tolar.io All Rights Reserved.
        </p>
      </div>
    </div>
  </footer>
);
