import * as React from 'react';
import { TableCell } from 'main/components';

interface Props {
  children: Array<React.ReactNode>;
}

export const TableHead: React.FC<Props> = ({ children }) => (
  <thead>
    <tr>
      {children.map((cell, index) => (
        <TableCell as="th" key={`thead-${index}`}>
          {cell}
        </TableCell>
      ))}
    </tr>
  </thead>
);
