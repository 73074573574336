import * as React from 'react';

interface Props {
  label?: string;
  className?: string;
  as?: 'td' | 'th';
  children: React.ReactNode;
}

export const TableCell: React.FC<Props> = ({
  label,
  className,
  children,
  as: Element = 'td'
}) => (
  <Element data-label={label} className={className}>
    {children}
  </Element>
);
