import * as React from 'react';
import iconCopy from 'main/assets/images/icon-copy.svg';
import iconCheck from 'main/assets/images/icon-check-2.svg';
import { SkeletonLoader, DateTime, FromTo } from 'main/components';
import { Transaction } from 'main/models';
import { Link } from 'react-router-dom';
import {
  attoTolarToTolar,
  bufferToRawNumber,
  formatManyDecimals,
  getBuffer,
  tolarValue,
} from 'main/util';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useCopyText } from 'modules/transaction-details';

interface Props {
  index?: number;
  price?: number;
  transaction?: Transaction;
  isLoading?: boolean;
  showInlineInternalTransactions?: boolean;
  onInternalTransactionClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  onSelectInputDataType?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  showInputDataAsHex?: boolean;
}

export const TransactionDetails: React.FC<Props> = ({
  index,
  transaction,
  isLoading,
  price,
  showInlineInternalTransactions,
  onInternalTransactionClick,
  onSelectInputDataType,
  showInputDataAsHex,
}) => {
  const isSingleTransiction = typeof index !== 'number';
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { isCopied, copyData } = useCopyText();

  if (!transaction)
    return (
      <p className="t-delta s-bottom--xlrg">
        Transaction with that hash has not been found on the blockchain yet.
        Please refresh few moments later.
      </p>
    );

  const {
    hash,
    excepted,
    block_index,
    transaction_timestamp,
    value,
    gas_used,
    gas_refunded,
    nonce,
    sender_address,
    receiver_address,
    new_address,
    data,
    internal_transactions,
  } = transaction;

  const getData = (data: any) =>
    isLoading ? <SkeletonLoader className="skeletonLoader--med" /> : data;

  const parseInputData = (inputData: string) => {
    return !!showInputDataAsHex || showInputDataAsHex
      ? inputData
      : Buffer.from(inputData, 'hex').toString('utf8');
  };

  const internalTransactions = (
    <span>
      <p>
        Contract{' '}
        {getData(
          <Link to={`/?query=${receiver_address}`}>{receiver_address}</Link>
        )}
      </p>
      <Link to="/" onClick={onInternalTransactionClick}>
        {internal_transactions ? internal_transactions.length : '0'} internal
        transactions
      </Link>
      <span className="opacity-80">{``}</span>
      {showInlineInternalTransactions
        ? internal_transactions.map((internal_transaction, index) => (
            <div key={index}>
              <FromTo
                inline
                value={`${tolarValue(internal_transaction.value)}
                 Tol ($${
                   !price || !internal_transaction.value
                     ? ' NaN'
                     : formatManyDecimals(
                         attoTolarToTolar(
                           bufferToRawNumber(
                             getBuffer(internal_transaction.value)
                           )
                         ) * price
                       )
                 })`}
                from={internal_transaction.from_address}
                to={internal_transaction.to_address}
              />
            </div>
          ))
        : null}
    </span>
  );

  return (
    <section
      className={`transactions__wrapper ${
        isExpanded && !isSingleTransiction && 'transactions__wrapper--active'
      } ${isSingleTransiction && 'transactions__wrapper--expanded'}`}
      key={transaction.hash}
    >
      {!isSingleTransiction && (
        <>
          <h4>Transaction #{index}</h4>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`transaction__button ${
              isExpanded && 'transaction__button--active'
            }`}
          />
        </>
      )}
      <ul className="transaction__list">
        <li className="transaction__list__item--wrap">
          <div className="transaction__list__item transaction__list__item--unexpanded-primary">
            <span className="t-small opacity-60 s-bottom--med">
              Transaction Hash
            </span>
            {getData(<Link to={`/transaction/${hash}`}>{hash}</Link>)}
          </div>
          {internal_transactions && internal_transactions.length > 0 ? (
            <div className="transaction__list__item transaction__list__item--unexpanded-primary">
              <span className="t-small opacity-60 s-bottom--med">
                Interacted with (To):
              </span>
              <span>{getData(internalTransactions)}</span>
            </div>
          ) : (
            <div className="transaction__list__item transaction__list__item--unexpanded-primary">
              <span className="t-small opacity-60 s-bottom--med">To</span>
              {getData(
                <Link to={`/?query=${receiver_address}`}>
                  {receiver_address}
                </Link>
              )}
            </div>
          )}
          <div className="transaction__list__item transaction__list__item--unexpanded-secondary">
            <span className="t-small opacity-60 s-bottom--med">Timestamp</span>
            <DateTime
              withoutClass={true}
              date={new Date(parseInt(transaction_timestamp))}
            />
          </div>
          <div className="transaction__list__item transaction__list__item--unexpanded-secondary">
            <span className="t-small opacity-60 s-bottom--med">Value</span>
            <span>
              {getData(
                `${tolarValue(value)}
               Tol ($${
                 !price || !value
                   ? ' NaN'
                   : formatManyDecimals(
                       attoTolarToTolar(bufferToRawNumber(getBuffer(value))) *
                         price
                     )
               })`
              )}
            </span>
          </div>
          <div className="transaction__list__item transaction__list__item--unexpanded-secondary">
            <span className="t-small opacity-60 s-bottom--med ">Status</span>
            {getData(
              <span
                className={`t- ${excepted ? 'color-warning' : 'color-success'}`}
              >
                {excepted ? 'FAILED' : 'PASSED'}
              </span>
            )}
          </div>
        </li>
        {(isExpanded || isSingleTransiction) && (
          <>
            <li className="transaction__list__item">
              <span className="t-small opacity-60 s-bottom--med">Block</span>
              <Link to={`/block/${block_index}`}>{`#${block_index}`}</Link>
            </li>
            <li className="transaction__list__item">
              <span className="t-small opacity-60 s-bottom--med">From</span>
              {getData(
                <Link to={`/?query=${sender_address}`}>{sender_address}</Link>
              )}
            </li>
            <li className="transaction__list__item">
              <span className="t-small opacity-60 s-bottom--med">
                New Address
              </span>
              {getData(
                <Link to={`/?query=${new_address}`}>{new_address}</Link>
              )}
            </li>
            <li className="transaction__list__item transaction__list__item--row">
              <div className="transaction__list__item transaction__list__item--special">
                <span className="t-small opacity-60 s-bottom--med">
                  Gas used
                </span>
                {getData(
                  `${gas_used ? bufferToRawNumber(getBuffer(gas_used)) : ''}`
                )}
              </div>
              <div className="transaction__list__item transaction__list__item--special">
                <span className="t-small opacity-60 s-bottom--med">
                  Gas refunded
                </span>
                {getData(
                  `${
                    gas_refunded
                      ? bufferToRawNumber(getBuffer(gas_refunded))
                      : ''
                  }`
                )}
              </div>
              <div className="transaction__list__item transaction__list__item--special">
                <span className="t-small opacity-60 s-bottom--med">Nonce</span>
                {`${getData(nonce ? bufferToRawNumber(getBuffer(nonce)) : '')}`}
              </div>
            </li>
            <li className="transaction__list__item">
              <div className="t-small opacity-60 s-bottom--med">
                Input Data
                {onSelectInputDataType && (
                  <div
                    id="stats-currency"
                    className="d-inline-block s-left--med"
                  >
                    <select
                      onChange={onSelectInputDataType}
                      className="input input--select"
                      value={showInputDataAsHex ? 'hex' : 'utf8'}
                    >
                      <option id="option-currency" value="hex">
                        hex
                      </option>
                      <option id="option-currency" value="utf8">
                        utf8
                      </option>
                    </select>
                    <CopyToClipboard
                      onCopy={copyData}
                      text={data ? parseInputData(data) : 'No data available!'}
                    >
                      <button className="btn--copy">
                        <img
                          src={isCopied ? iconCheck : iconCopy}
                          alt="Copy icon"
                          className="s-left--med align-self-center"
                        />
                      </button>
                    </CopyToClipboard>
                  </div>
                )}
              </div>
              {isLoading ? (
                <SkeletonLoader className="skeletonLoader--xlrg" />
              ) : data ? (
                <div>
                  <span> {parseInputData(data)}</span>
                </div>
              ) : (
                'No data available!'
              )}
            </li>
          </>
        )}
      </ul>
    </section>
  );
};
