import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
  text: string;
  length?: number;
  link?: boolean;
}

export const ShortenedText: React.FC<Props> = ({
  text,
  className,
  link,
  length = 26,
}) =>
  link ? (
    <Link
      className={`t-shorten-wrapper ${className || ''}`}
      to={`/?query=${text}`}
    >
      <span className="t-shorten">{text}</span>
    </Link>
  ) : (
    <span className={`t-shorten-wrapper ${className || ''}`}>
      <span className="t-shorten">{text}</span>
    </span>
  );
