import * as React from 'react';
import { BackButton } from 'main/components';

interface Props {
  children: React.ReactNode;
  util?: React.ReactNode;
  className?: string;
}

export const TitleWithUtil: React.FC<Props> = ({
  children,
  util,
  className
}) => (
  <h1 className="t-delta s-bottom--xlrg d-flex title--withUtil">
    {util || <BackButton className="title__util" />}
    <div className={className}>{children}</div>
  </h1>
);
