export const EVM_VERSIONS = [
    'london',
    'petersburg',
    'istanbul',
    'constantinople',
    'byzantium',
    'spuriousDragon',
    'tangerineWhistle',
    'homestead',
];
