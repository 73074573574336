import * as React from 'react';
import anime from 'animejs';

interface Props {
  isActive?: boolean;
  title: string;
  dataLength?: number;
  children: React.ReactNode;
}

interface State {
  isActive: boolean;
}

export class Toggle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isActive: props.isActive || false,
    };
  }

  handleToggleClick = () => this.setState({ isActive: !this.state.isActive });

  handleAnimation = () => {
    const { isActive } = this.state;
    const { title, dataLength } = this.props;
    const contentId = title.toLowerCase().replace(/\s/g, '-');
    if (isActive) {
      anime({
        targets: `#toggle-content-${contentId}`,
        maxHeight: `${224 * (dataLength || 1)}px`,
        duration: 800,
        easing: 'cubicBezier(.5, .05, .1, .3)',
      });
    } else {
      anime({
        targets: `#toggle-content-${contentId}`,
        maxHeight: '0',
        duration: 800,
        easing: 'cubicBezier(0,1,0,1)',
      });
    }
  };

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.isActive !== undefined &&
      nextProps.isActive !== this.state.isActive
    ) {
      this.setState({
        ...this.state,
        isActive: nextProps.isActive,
      });
    }
  }

  componentDidUpdate = () => {
    this.handleAnimation();
  };

  componentDidMount = () => {
    this.handleAnimation();
  };

  render() {
    const { title, children } = this.props;
    const { isActive } = this.state;

    const btnClassName = isActive
      ? 'toggle__btn toggle__btn--active'
      : 'toggle__btn';

    const contentId = title.toLowerCase().replace(/\s/g, '-');

    return (
      <React.Fragment>
        <div id="toggle-button" className="s-bottom--lrg">
          <button
            className={`btn btn--med btn--primary ${btnClassName}`}
            onClick={this.handleToggleClick}
          >
            {title}
            {isActive ? (
              <span className="chevron chevron--up chevron--lrg s-left--med" />
            ) : (
              <span className="chevron chevron--down chevron--lrg s-left--med" />
            )}
          </button>
        </div>
        <div id={`toggle-content-${contentId}`} className="toggle__content">
          {children}
        </div>
      </React.Fragment>
    );
  }
}
