import * as views from '../views';

export interface AppRoute {
    title: string;
    path?: string;
    component: any;
    exact?: boolean;
    key?: number;
}

export const routes: AppRoute[] = [
    {
        title: 'Homepage',
        path: '/',
        component: views.Homepage,
        exact: true,
    },
    {
        title: 'Block Details',
        path: '/block/:blockId',
        component: views.Block,
        exact: true,
    },
    {
        title: 'Transaction Details',
        path: '/transaction/:transactionId',
        component: views.Transaction,
        exact: true,
    },
    {
        title: 'Verification',
        path: '/verification',
        component: views.Verification,
        exact: true,
    },
    {
        /**
         * Error page doesn't need to have a route prop, as it is a fallback route
         * Key is set to 999 to ensure this route is rendered last
         */
        title: 'Error 404',
        component: views.Error404,
        key: 999,
    },
];
