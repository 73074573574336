import { TitleWithUtil } from 'main/components';
import { VerificationData } from 'main/models';
import { ApiService } from 'main/services';
import React, { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { COMPILER_VERSIONS, EVM_VERSIONS } from 'modules/verification';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { toast, ToastContainer } from 'react-toastify';

export const VerificationForm = () => {
  const client = new ApiService();

  const form = useForm<VerificationData>({
    mode: 'onSubmit',
    defaultValues: {
      contract_code: '',
      contract_address: '',
      compiler_version: 'v0.8.7+commit.e28d00a7',
      contract_name: '',
      contract_filename: '',
      evm_version: 'london',
      optimized: 'false',
      optimization_runs: '200',
    },
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = form;

  useEffect(() => {
    injectStyle();
  }, []);

  const onSubmit: SubmitHandler<VerificationData> = async (
    data: VerificationData
  ) => {
    await client
      .verifyContract(data)
      .then((res) => {
        if (res.statusCode === 200) toast.success(res.status);
        if (res.statusCode !== 200) toast.error(res.status);
      })
      .catch();

    reset();
  };

  return (
    <section className="wrapper">
      <TitleWithUtil className="d-flex">Verification</TitleWithUtil>
      <div>
        <h4 className="verification__text">Solidity Code</h4>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="verification__textare__wrapper">
              <Controller
                rules={{ required: true }}
                control={control}
                name="contract_code"
                render={({ onChange, value }) => (
                  <textarea
                    value={value}
                    onChange={onChange}
                    className="verification__textare"
                  ></textarea>
                )}
              />
              {errors.contract_code && (
                <p className="verification__error">Required</p>
              )}
            </div>
            <div className="verification__input__wrapper">
              <label htmlFor="address" className="verification__label">
                Contract Address
              </label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="contract_address"
                render={({ onChange, value }) => (
                  <input
                    value={value}
                    onChange={onChange}
                    className="input--text verification__input"
                    type="text"
                    id="address"
                  />
                )}
              />
              {errors.contract_address && (
                <p className="verification__error">Required</p>
              )}
            </div>
            <div className="verification__input__wrapper">
              <label htmlFor="name" className="verification__label">
                Contract Name
              </label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="contract_name"
                render={({ onChange, value }) => (
                  <input
                    value={value}
                    onChange={onChange}
                    className="input--text verification__input"
                    type="text"
                    id="name"
                  />
                )}
              />
              {errors.contract_name && (
                <p className="verification__error">Required</p>
              )}
            </div>
            <div className="verification__input__wrapper">
              <label htmlFor="filename" className="verification__label">
                Contract Filename
              </label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="contract_filename"
                render={({ onChange, value }) => (
                  <input
                    value={value}
                    onChange={onChange}
                    className="input--text verification__input"
                    type="text"
                    id="filename"
                  />
                )}
              />
              {errors.contract_filename && (
                <p className="verification__error">Required</p>
              )}
            </div>
            <div className="verification__input__wrapper">
              <label htmlFor="filename" className="verification__label">
                Compiler Version
              </label>
              <Controller
                control={control}
                name="compiler_version"
                render={({ onChange, value }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    id="compiler"
                    className="verification__select"
                  >
                    {COMPILER_VERSIONS.map((version, i) => (
                      <option value={version} key={i}>
                        {version}
                      </option>
                    ))}
                  </select>
                )}
              />
            </div>
            <div className="verification__input__wrapper">
              <label htmlFor="filename" className="verification__label">
                Evm Version
              </label>
              <Controller
                control={control}
                name="evm_version"
                render={({ onChange, value }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    id="evm"
                    className="verification__select"
                  >
                    {EVM_VERSIONS.map((version, i) => (
                      <option value={version} key={i}>
                        {version}
                      </option>
                    ))}
                  </select>
                )}
              />
            </div>
            <div className="verification__input__wrapper">
              <label htmlFor="optimization" className="verification__label">
                Optimization
              </label>
              <div className="d-flex verification__input__wrapper--special">
                <Controller
                  control={control}
                  name="optimized"
                  render={({ onChange, value }) => (
                    <select
                      value={value}
                      onChange={onChange}
                      id="optimization"
                      className="verification__select verification__select--special"
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  )}
                />
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="optimization_runs"
                  render={({ onChange, value }) => (
                    <input
                      value={value}
                      onChange={onChange}
                      className="input--text verification__input verification__input--special"
                      type="text"
                      id="optimization"
                    />
                  )}
                />
                {errors.optimization_runs && (
                  <p className="verification__error">Required</p>
                )}
              </div>
            </div>
            <button type="submit" className="verification__button">
              Verify
            </button>
          </form>
        </FormProvider>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
    </section>
  );
};
