import React from 'react';

export function useCopyText() {
    const [isCopied, setIsCopied] = React.useState(false);

    function copyData() {
        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    }

    return { isCopied, copyData };
}
