import * as React from 'react';
import { TitleWithUtil, ShortenedText, Loader } from 'main/components';
import {
  BlockDetails,
  TransactionDetails,
  BlockTransactionList,
  SearchResultsNavigation,
} from 'modules';
import { QueryResults } from 'main/models/QueryResults';
import { PayloadType } from 'main/constants';
import { Block, Transaction } from 'main/models';
import { SearchResultsPagination } from 'modules/pagination';
import { TransactionsList } from 'modules/transaction-details';
import { Contract } from 'modules/contract';

interface Props {
  searchResult?: QueryResults<any>;
  searchError?: string;
  searchQuery: string;
  isLoading: boolean;
  price?: number;
}

export class SearchResults extends React.Component<Props> {
  state = {
    showTransactions: undefined,
    showInputDataAsHex: true,
    searchType: 'transactions',
  };

  private scrollToRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.scrollToRef = React.createRef();
  }

  onTransactionClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    this.setState({ showTransactions: true }, () => {
      const { offsetTop } = this.scrollToRef.current!;
      window.scrollTo({ behavior: 'smooth', left: 0, top: offsetTop });
    });
  };

  onSelectInputDataType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    this.setState({
      ...this.state,
      showInputDataAsHex: value === 'hex',
    });
  };

  handleSearchType = (searchType: 'transactions' | 'contract') => {
    this.setState({ searchType });
  };

  getSearchResultBlock = () => {
    const { searchType } = this.state;
    const { searchResult, searchError, price, searchQuery } = this.props;

    const numberOfResults =
      searchResult && searchResult.payload.transactions
        ? searchResult.payload.transactions.length
        : 1;

    if (searchError) {
      return searchError;
    }

    if (!searchResult) {
      return 'No result found';
    }

    const { type, payload, contract } = searchResult;
    const isContract = contract && contract.deployedBytecode;

    switch (type) {
      case PayloadType.Block:
        const block = payload as Block;
        return (
          <section>
            <BlockDetails
              data={block}
              onTransactionClick={this.onTransactionClick}
            />
            <div ref={this.scrollToRef}>
              <BlockTransactionList
                data={block.transactions}
                isActive={this.state.showTransactions}
              />
            </div>
          </section>
        );
      case PayloadType.Blocks:
        const blocks = payload as Block[];
        return blocks.map((block) => (
          <section key={block.block_index}>
            <BlockDetails
              data={block}
              onTransactionClick={this.onTransactionClick}
            />
            <div ref={this.scrollToRef}>
              <BlockTransactionList
                data={block.transactions}
                isActive={this.state.showTransactions}
              />
            </div>
          </section>
        ));
      case PayloadType.Transaction:
        return (
          <section className="results__wrapper">
            <TransactionDetails
              transaction={payload as Transaction}
              price={price}
              onSelectInputDataType={this.onSelectInputDataType}
              showInputDataAsHex={this.state.showInputDataAsHex}
            />
          </section>
        );
      case PayloadType.Transactions:
        const transactions = payload.transactions as Transaction[];
        return (
          <>
            {searchType === 'transactions' ? (
              <TransactionsList
                transactions={transactions}
                price={price}
                onSelectInputDataType={this.onSelectInputDataType}
                showInputDataAsHex={this.state.showInputDataAsHex}
                numberOfResults={numberOfResults}
                searchQuery={searchQuery}
              />
            ) : (
              contract &&
              !!isContract && (
                <Contract searchQuery={searchQuery} contract={contract} />
              )
            )}
          </>
        );
      default:
        return 'No result found';
    }
  };

  render() {
    const { searchType } = this.state;
    const { searchQuery, isLoading, searchResult } = this.props;
    const numberOfResults =
      searchResult && searchResult.payload.transactions
        ? searchResult.payload.transactions.length
        : 1;
    const isContract =
      searchResult &&
      searchResult.contract &&
      searchResult.contract.deployedBytecode;
    const isVerified =
      searchResult && searchResult.contract && searchResult.contract.contract;

    if (!searchQuery) return null;

    return isLoading ? (
      <Loader />
    ) : (
      <article>
        <div className="s-bottom--lrg">
          <TitleWithUtil className="d-flex">
            <span className="t-nowrap s-right--sml">Search result for</span>
            <strong>
              <ShortenedText text={searchQuery} />
            </strong>
          </TitleWithUtil>
        </div>
        {searchResult && searchResult.type === PayloadType.Transactions && (
          <SearchResultsNavigation
            numberOfResults={numberOfResults}
            searchType={searchType}
            handleSearchType={this.handleSearchType}
            isContract={!!isContract}
            isVerified={!!isVerified}
          />
        )}
        <div>{this.getSearchResultBlock()}</div>
        {searchType === 'transactions' &&
          numberOfResults > 1 &&
          searchResult &&
          searchResult.type === PayloadType.Transactions && (
            <SearchResultsPagination numberOfResults={numberOfResults} />
          )}
      </article>
    );
  }
}
