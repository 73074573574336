import * as React from 'react';
import { connect } from 'react-redux';
import { LineGraph, DataSelector, Status } from 'modules';
import { CoingeckoThunks, CoingeckoState } from 'modules/coingecko';
import { ReduxDispatch } from 'modules/redux-store';
import { ApplicationState } from 'modules/redux-store';
import { Skeleton } from './Skeleton';

const sampleDataset = [
  {
    id: 'data-live',
    label: 'Live',
    days: 1
  },
  {
    id: 'data-today',
    label: 'Today',
    days: 1
  },
  {
    id: 'data-thisWeek',
    label: 'This week',
    days: 7
  },
  {
    id: 'data-14Days',
    label: '14 days',
    days: 14
  }
];

interface DispatchProps {
  getTolarChartHistory: (
    days: number | 'max' | undefined,
    currency: string
  ) => void;
}

interface ReduxProps {
  currency: string;
  coingecko: CoingeckoState;
}

interface OwnProps {
  className?: string;
}

type Props = OwnProps & DispatchProps & ReduxProps;

class Stats extends React.Component<Props> {
  getHistoryData = (range: number) => {
    this.props.getTolarChartHistory(range, this.props.currency);
  };

  getData = (activeDataId: string) => {
    const { coingecko, currency } = this.props;

    if (coingecko.isLoadingHistory || coingecko.isLoading) {
      return <Skeleton />;
    }

    const { history, percentage } = coingecko;

    return (
      <React.Fragment>
        {}
        <Status percentage={percentage[currency]} />
        <LineGraph
          currency={currency}
          getData={this.getHistoryData}
          data={history}
          isRealtime={activeDataId === 'data-live'}
        />
      </React.Fragment>
    );
  };

  render() {
    const { currency } = this.props;

    return (
      <DataSelector
        currency={currency}
        className="home__stats"
        getData={this.getHistoryData}
        dataList={sampleDataset}
      >
        {this.getData}
      </DataSelector>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
  return { coingecko: state.coingecko, currency: state.settings.currency };
};

const mapDispatchToProps = (dispatch: ReduxDispatch): DispatchProps => ({
  getTolarChartHistory: (days, currency) =>
    dispatch(CoingeckoThunks.getTolarChartHistory(days, currency))
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
