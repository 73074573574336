import { ReduxDispatch } from 'modules/redux-store';
import { BlockActions } from './actions';
import { ApiService } from 'main/services';

const client = new ApiService();

const getLatestBlocks = (noOfBlocks: number = 5) => async (
    dispatch: ReduxDispatch
) => {
    if (noOfBlocks > 5) {
        noOfBlocks = 5;
    }

    // dispatch(BlockActions.request());
    const latestBlocks = await client.getLatestBlocks(noOfBlocks);

    if (typeof latestBlocks === 'string') {
        dispatch(BlockActions.error(latestBlocks));
        return;
    }

    dispatch(BlockActions.getLatestBlocks(latestBlocks.latest_blocks));
};

const getBlockDetails = (hashOrIndex: string) => async (
    dispatch: ReduxDispatch
) => {
    dispatch(BlockActions.request());
    const blockDetails = await client.getBlockDetails(hashOrIndex);

    if (typeof blockDetails === 'string') {
        dispatch(BlockActions.error(blockDetails));
        return;
    }

    if (blockDetails) {
        dispatch(BlockActions.getBlock(blockDetails));
        return;
    }

    dispatch(BlockActions.error('No block found!'));
};

export const BlockThunks = {
    getLatestBlocks,
    getBlockDetails
};
