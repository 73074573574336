import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  FromTo,
  ShortenedText,
  Table,
  Toggle,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'main/components';
import { InternalTransaction } from 'main/models';
import { Skeleton } from './Skeleton';
import { tolarValue } from 'main/util';

interface Props {
  data: Array<InternalTransaction>;
  isLoading?: boolean;
  isActive?: boolean;
}

export const BlockInternalTransactionList: React.FC<Props> = ({
  data,
  isLoading,
  isActive,
}) => {
  return (
    <Toggle
      title="All Internal Transactions"
      isActive={isActive}
      dataLength={data.length}
    >
      <Table className="table table--med table-responsive--desktop s-bottom--xlrg t-nowrap">
        <TableHead>
          <span className="t-small opacity-50">Parent Hash</span>
          <span className="t-small opacity-50">From / To</span>
          <span className="t-small opacity-50">Type</span>
          <span className="t-small opacity-50">Value</span>
        </TableHead>
        <TableBody>
          {isLoading ? (
            [...Array(8)].map((_, index: number) => (
              <Skeleton key={`skeleton-blockTransactionList-${index}`} />
            ))
          ) : !data || data.length === 0 ? (
            <TableRow key="empty">
              <TableCell label="Message">No data available!</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          ) : (
            data.map(
              ({
                from_address,
                to_address,
                parent_transaction_hash,
                type,
                value,
              }: InternalTransaction) => (
                <TableRow key={parent_transaction_hash}>
                  <TableCell label="Parent Hash">
                    <Link
                      className="t-small"
                      to={`/transaction/${parent_transaction_hash}`}
                    >
                      <ShortenedText text={parent_transaction_hash} />
                    </Link>
                  </TableCell>
                  <TableCell label="From/To">
                    <FromTo inline from={from_address} to={to_address} />
                  </TableCell>
                  <TableCell label="Type">
                    <span className="t-small opacity-60 t-tiny">
                      {type === 0 ? 'CALL' : type}
                    </span>
                  </TableCell>
                  <TableCell label="Value">
                    <span>Tol {tolarValue(value)}</span>
                  </TableCell>
                </TableRow>
              )
            )
          )}
        </TableBody>
      </Table>
    </Toggle>
  );
};
