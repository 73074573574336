enum GeneralActions {
    Request = 'settings/currency/REQUEST',
    Error = 'settings/currency/ERROR'
}

enum SetActions {
    SetSettingsCurrency = 'settings/currency/SET'
}

export const SettingsActionTypes = {
    ...GeneralActions,
    ...SetActions
};
