import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  BlockTransactionList,
  BlockDetails,
  BlockThunks,
  BlockInternalTransactionList,
} from 'modules';
import { TitleWithUtil } from 'main/components';
import { Block as BlockObject } from 'main/models';
import anime from 'animejs';
import { connect } from 'react-redux';
import { ReduxDispatch, ApplicationState } from 'modules/redux-store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

interface DispatchProps {
  getBlockDetails: (blockHash: string) => void;
}

interface ReduxProps {
  block?: BlockObject;
  isLoading: boolean;
  error?: string;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

interface State {
  showTransactions?: boolean;
  showInternalTransactions?: boolean;
}

class Block extends React.Component<Props, State> {
  state = {
    showTransactions: undefined,
    showInternalTransactions: undefined,
  };

  private scrollToTransactionsRef: React.RefObject<HTMLDivElement>;
  private scrollToInternalTransactionsRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.scrollToTransactionsRef = React.createRef();
    this.scrollToInternalTransactionsRef = React.createRef();
  }

  componentWillMount() {
    const { block } = this.props;

    if (
      this.props.match.params &&
      this.props.match.params.blockId &&
      (!block || block.hash !== this.props.match.params.blockId)
    ) {
      this.props.getBlockDetails(this.props.match.params.blockId);
    }
  }

  componentDidMount() {
    /* Animate splash header of the section to have a nice fade in */
    anime({
      targets: 'main',
      opacity: [0, 1],
      duration: 300,
      easing: 'easeOutQuad',
    });
  }

  onTransactionClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    this.setState({ ...this.state, showTransactions: true }, () => {
      const { offsetTop } = this.scrollToTransactionsRef.current!;
      window.scrollTo({ behavior: 'smooth', left: 0, top: offsetTop });
    });
  };

  onInternalTransactionClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    this.setState({ ...this.state, showInternalTransactions: true }, () => {
      const { offsetTop } = this.scrollToInternalTransactionsRef.current!;
      window.scrollTo({ behavior: 'smooth', left: 0, top: offsetTop });
    });
  };

  render() {
    const { block, isLoading, error } = this.props;

    if (error) {
      return (
        <section className="wrapper">
          <TitleWithUtil>{error}</TitleWithUtil>
        </section>
      );
    }

    if (!block) {
      return (
        <section className="wrapper">
          <TitleWithUtil>No result found!</TitleWithUtil>
        </section>
      );
    }

    const blockIndex = block ? block.block_index : '0';

    return (
      <section className="wrapper">
        <Helmet>
          <title>Block #{blockIndex.toString()} | Tolar Explorer</title>
        </Helmet>
        <TitleWithUtil>
          <strong>Block</strong> #{blockIndex}
        </TitleWithUtil>
        <BlockDetails
          isLoading={isLoading}
          data={block}
          onTransactionClick={this.onTransactionClick}
          onInternalTransactionClick={this.onInternalTransactionClick}
        />
        <div ref={this.scrollToTransactionsRef}>
          <BlockTransactionList
            isLoading={isLoading}
            data={block.transactions}
            isActive={this.state.showTransactions}
          />
        </div>
        {block.internal_transactions && block.internal_transactions.length > 0 && (
          <div ref={this.scrollToInternalTransactionsRef}>
            <BlockInternalTransactionList
              isLoading={isLoading}
              data={block.internal_transactions}
              isActive={this.state.showInternalTransactions}
            />
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
  return {
    block: state.block.selectedBlock,
    isLoading: state.block.isLoading,
    error: state.block.error,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch): DispatchProps => ({
  getBlockDetails: (blockHash: string) =>
    dispatch(BlockThunks.getBlockDetails(blockHash)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Block);
