import { CURRENCY_INTL } from 'main/constants';

export const formatCurrency = (value: number, currency: string) => {
    const intlConfig = {
        style: 'currency',
        useGrouping: false,
        currency: currency.toUpperCase(),
        maximumSignificantDigits: 10
    };

    return new Intl.NumberFormat(
        CURRENCY_INTL[currency] || CURRENCY_INTL.usd,
        intlConfig
    ).format(value);
};
