import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props {
  className?: string;
}

class BackButton extends React.Component<Props & RouteComponentProps<Props>> {
  handleBackButtonClick = () => this.props.history.goBack();

  render() {
    const { className } = this.props;
    return (
      <button
        onClick={this.handleBackButtonClick}
        className={`btn s-right--sml ${className}`}
      >
        <span className="chevron chevron--rounded chevron--left" />
      </button>
    );
  }
}

export default withRouter(BackButton);
