import * as React from 'react';
import { ValidatedInput } from 'main/components';

interface Props {
  value: string;
  onValueChange: (
    name: string,
    value: string,
    isValid: boolean | undefined
  ) => void;
  onValueSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export class Search extends React.Component<Props> {
  render() {
    const { value, onValueChange, onValueSubmit } = this.props;
    return (
      <form
        autoComplete="off"
        className="search s-bottom--xlrg"
        onSubmit={onValueSubmit}
      >
        <ValidatedInput
          name="search"
          value={value}
          placeholder="Search by Hash, Block or Address"
          isValid={true}
          onValueChange={onValueChange}
          className="search__input search__input--text"
        />
        <button
          type="submit"
          className="btn btn--primary btn--lrg search__input search__input--button"
        >
          Search
        </button>
      </form>
    );
  }
}
