import { TransactionAction } from './actions';
import { TransactionActionTypes } from './types';
import { Transaction } from 'main/models';

export interface TransactionState {
    transactions: Transaction[];
    transaction?: Transaction;
    isLoading: boolean;
    error?: string;
}

const INITIAL_STATE: TransactionState = {
    transactions: [],
    transaction: undefined,
    isLoading: true,
    error: undefined
};

export const transactionReducer = (
    state: TransactionState = INITIAL_STATE,
    action: TransactionAction
) => {
    switch (action.type) {
        case TransactionActionTypes.Request:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case TransactionActionTypes.Error:
        case TransactionActionTypes.GetTransaction:
        case TransactionActionTypes.GetLatestTransactions:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        default:
            return { ...state };
    }
};
