import { CURRENCY_CODES } from 'main/constants';

export type MarketValue = {
    [CURRENCY_CODES.eur]: number;
    [CURRENCY_CODES.usd]: number;
};

export interface MarketData {
    market_cap: MarketValue;
    percentage: MarketValue;
    price: MarketValue;
    transactions: MarketValue;
}
