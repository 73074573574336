import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  TransactionDetails,
  TransactionThunks,
  CoingeckoThunks,
  BlockInternalTransactionList,
} from 'modules';
import { TitleWithUtil } from 'main/components';
import { Transaction as TransactionClass } from 'main/models';
import anime from 'animejs';
import { connect } from 'react-redux';
import { ReduxDispatch, ApplicationState } from 'modules/redux-store';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

interface DispatchProps {
  getTransaction: (hash: string) => void;
  getTolarValue: () => void;
}

interface ReduxProps {
  price: number;
  transaction?: TransactionClass;
  isLoading: boolean;
  error?: string;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

interface State {
  showInternalTransactions?: boolean;
  showInlineInternalTransactions: boolean;
  showInputDataAsHex: boolean;
}

export class Transaction extends React.Component<Props, State> {
  state = {
    showInternalTransactions: undefined,
    showInlineInternalTransactions: false,
    showInputDataAsHex: true,
  };

  private scrollToInternalTransactionsRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.scrollToInternalTransactionsRef = React.createRef();
  }

  componentWillMount() {
    const { transaction, price } = this.props;
    if (
      this.props.match.params &&
      this.props.match.params.transactionId &&
      (!transaction ||
        transaction.hash !== this.props.match.params.transactionId)
    ) {
      this.props.getTransaction(this.props.match.params.transactionId);
    }

    if (!price) {
      this.props.getTolarValue();
    }
  }

  componentDidMount() {
    /* Animate splash header of the section to have a nice fade in */
    anime({
      targets: 'main',
      opacity: [0, 1],
      duration: 300,
      easing: 'easeOutQuad',
    });
  }

  onInternalTransactionClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      showInlineInternalTransactions:
        !this.state.showInlineInternalTransactions,
    });
    // this.setState({ ...this.state, showInternalTransactions: true }, () => {
    //   const { offsetTop } = this.scrollToInternalTransactionsRef.current!;
    //   window.scrollTo({ behavior: 'smooth', left: 0, top: offsetTop });
    // });
  };

  onSelectInputDataType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    this.setState({
      ...this.state,
      showInputDataAsHex: value === 'hex',
    });
  };

  render() {
    return (
      <section className="wrapper">
        <Helmet>
          <title>Transaction Details | Tolar Explorer</title>
        </Helmet>
        <TitleWithUtil>
          <strong>Transaction</strong> details
        </TitleWithUtil>
        <TransactionDetails
          isLoading={this.props.isLoading}
          transaction={this.props.transaction}
          price={this.props.price}
          onInternalTransactionClick={this.onInternalTransactionClick}
          onSelectInputDataType={this.onSelectInputDataType}
          showInputDataAsHex={this.state.showInputDataAsHex}
          showInlineInternalTransactions={
            this.state.showInlineInternalTransactions
          }
        />
        {this.props.transaction && (
          <div ref={this.scrollToInternalTransactionsRef}>
            <BlockInternalTransactionList
              isLoading={this.props.isLoading}
              data={
                this.props.transaction
                  ? this.props.transaction.internal_transactions
                  : []
              }
              isActive={this.state.showInternalTransactions}
            />
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
  return {
    price: state.coingecko.price[state.settings.currency],
    transaction: state.transaction.transaction,
    isLoading: state.transaction.isLoading,
    error: state.transaction.error,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch): DispatchProps => ({
  getTransaction: (hash: string) =>
    dispatch(TransactionThunks.getTransactionDetails(hash)),
  getTolarValue: () => dispatch(CoingeckoThunks.getCurrentValue()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Transaction);
