enum GeneralActions {
    Request = 'coingecko/REQUEST',
    Error = 'coingecko/ERROR'
}

enum GetActions {
    GetTolarChartHistory = 'coingecko/GET_TOLAR_CHART_HISTORY',
    GetTolarValue = 'coingecko/GET_TOLAR_VALUE'
}

export const CoingeckoActionTypes = {
    ...GeneralActions,
    ...GetActions
};
