import { ReduxDispatch } from 'modules/redux-store';
import { TransactionActions } from './actions';
import { ApiService } from 'main/services';

const client = new ApiService();

const getLatestTransactions = (noOfTransactions: number = 5) => async (
    dispatch: ReduxDispatch
) => {
    if (noOfTransactions > 5) {
        noOfTransactions = 5;
    }

    // dispatch(TransactionActions.request());
    const latestTransactions = await client.getLatestTransactions(
        noOfTransactions
    );

    if (typeof latestTransactions === 'string') {
        dispatch(TransactionActions.error(latestTransactions));
        return;
    }

    dispatch(
        TransactionActions.getLatestTransactions(
            latestTransactions.latest_transactions
        )
    );
};
const getTransactionDetails = (hash: string) => async (
    dispatch: ReduxDispatch
) => {
    dispatch(TransactionActions.request());
    const transaction = await client.getTransactionDetails(hash);

    if (typeof transaction === 'string') {
        dispatch(TransactionActions.error(transaction));
        return;
    }

    if (transaction) {
        dispatch(TransactionActions.getTransaction(transaction));
        return;
    }

    dispatch(TransactionActions.error('No transaction found!'));
};

export const TransactionThunks = {
    getLatestTransactions,
    getTransactionDetails
};
