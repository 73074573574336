import {
  paginationSelector,
  useTransactionPagination,
} from 'modules/pagination';
import React from 'react';
import { useSelector } from 'react-redux';
import iconCheck from 'main/assets/images/icon-check-2.svg';

interface Props {
  numberOfResults: number;
  searchType: string;
  handleSearchType: (type: 'transactions' | 'contract') => void;
  isContract: boolean;
  isVerified: boolean;
}

export const SearchResultsNavigation: React.FC<Props> = ({
  numberOfResults,
  handleSearchType,
  searchType,
  isContract,
  isVerified,
}) => {
  const { resultsPerPage } = useSelector(paginationSelector.getState);
  const { handleResultsPerPageChange, currentPage, maxNumber } =
    useTransactionPagination(numberOfResults);

  return (
    <nav>
      <div className="search__results">{numberOfResults} Results</div>
      <div className="search__navigation">
        <div className="search__navigation__buttons">
          <button
            className={`s-right--tny search__navigation__button ${
              searchType === 'transactions' &&
              'search__navigation__button--active'
            }`}
            onClick={() => handleSearchType('transactions')}
          >
            Transactions
          </button>
          {isContract && (
            <button
              className={`search__navigation__button ${
                searchType === 'contract' &&
                'search__navigation__button--active'
              }`}
              onClick={() => handleSearchType('contract')}
            >
              {isVerified && (
                <img src={iconCheck} alt="" className="s-right--med" />
              )}
              Contract
            </button>
          )}
        </div>
        {searchType === 'transactions' && numberOfResults > 1 && (
          <div className="search__navigation__results">
            <div className="s-bottom--med s-right--med">
              {1 + (Number(currentPage) - 1) * resultsPerPage}-
              {maxNumber(Number(currentPage) * resultsPerPage)} of{' '}
              {numberOfResults}
            </div>
            <div>
              Results per page
              <select
                id="resultsPerPage"
                className="pagination__select"
                value={resultsPerPage}
                onChange={handleResultsPerPageChange}
              >
                {[...Array(10)].map((_, i) => (
                  <option key={i} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
