import * as React from 'react';
import anime from 'animejs';

export default class Loader extends React.Component {
  componentDidMount() {
    anime({
      targets: '#loader-progress',
      width: ['0%', '100%'],
      duration: 5500,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      complete: () => {
        anime({
          targets: '#loader',
          opacity: [1, 0],
          duration: 300,
          easing: 'easeInOutQuad',
          complete: () => {
            anime({
              targets: '#loader',
              maxHeight: 0
            });
          }
        });
      }
    });
  }

  render() {
    return (
      <div id="loader" className="loader">
        <div className="loader__wrapper">
          <div id="loader-progress" className="loader__progress" />
        </div>
      </div>
    );
  }
}
