import { ContractDetails } from 'main/models';
import React from 'react';
import { Link } from 'react-router-dom';
import iconCheck from 'main/assets/images/icon-check-2.svg';

interface Props {
  searchQuery: string;
  contract: ContractDetails;
}

export const Contract: React.FC<Props> = ({ searchQuery, contract }) => {
  return (
    <div>
      {!!contract.contract && (
        <p className="contract__message">
          <img src={iconCheck} alt="" className="s-right--med" />
          Contract code verified
        </p>
      )}
      <h5 className="contract__title">
        {!!contract.contract ? 'Solidity Code' : 'Byte Code'}
      </h5>
      <div className="contract__container">
        {contract.contract || contract.deployedBytecode}
      </div>
      {!contract.contract && (
        <Link
          className="contract__button"
          to={`/verification/?query=${searchQuery}`}
        >
          Verify
        </Link>
      )}
    </div>
  );
};
