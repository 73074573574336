import { SearchAction } from './actions';
import { SearchActionTypes } from './types';
import { QueryResults } from 'main/models/QueryResults';

export interface SearchState {
    searchResults?: QueryResults<any>;
    isLoading: boolean;
    error?: string;
}

const INITIAL_STATE: SearchState = {
    searchResults: undefined,
    isLoading: true,
    error: undefined
};

export const searchReducer = (
    state: SearchState = INITIAL_STATE,
    action: SearchAction
) => {
    switch (action.type) {
        case SearchActionTypes.Request:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case SearchActionTypes.Error:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        case SearchActionTypes.Result:
            return {
                ...state,
                ...action.payload,
                error: undefined,
                isLoading: false
            };
        default:
            return { ...state };
    }
};
