import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PaginationActions, paginationSelector } from 'modules/pagination';
import { ChangeEvent } from 'react';

export function useTransactionPagination(numberOfResults: number) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { resultsPerPage } = useSelector(paginationSelector.getState);
    const maxResults = numberOfResults < 10 ? numberOfResults : 10;
    const currentPage =
        new URLSearchParams(window.location.search).get('page') || '1';
    const searchQuery =
        new URLSearchParams(window.location.search).get('query') || '';
    const maxPages = Math.ceil(numberOfResults / resultsPerPage);

    function sliceNumbers() {
        let firstSliceNumber = 0;
        let lastSliceNumber = 3;

        if (Number(currentPage) > 2 && maxPages > 4) {
            firstSliceNumber =
                Number(currentPage) < maxPages - 2
                    ? Number(currentPage) - 3
                    : maxPages - 5;

            lastSliceNumber =
                Number(currentPage) + 2 < maxPages
                    ? Number(currentPage)
                    : maxPages - 2;
        }

        return { firstSliceNumber, lastSliceNumber };
    }

    function onNumberChange(pageNumber: number) {
        history.push({
            search: `?query=${searchQuery}&page=${pageNumber}`,
        });
    }

    function onArrowChange(direction: 'next' | 'previous') {
        const change = direction === 'next' ? 1 : -1;
        const pageNumber = Number(currentPage) + change;

        history.push({
            search: `?query=${searchQuery}&page=${pageNumber}`,
        });
    }

    function maxNumber(pageResults: number) {
        if (pageResults < numberOfResults) return pageResults;
        return numberOfResults;
    }

    function handleResultsPerPageChange({
        currentTarget: { value },
    }: ChangeEvent<HTMLSelectElement>) {
        dispatch(PaginationActions.setResultsPerPage(Number(value)));

        history.push({
            search: `?query=${searchQuery}&page=${1}`,
        });
    }

    return {
        maxResults,
        currentPage,
        maxPages,
        onNumberChange,
        onArrowChange,
        maxNumber,
        sliceNumbers,
        handleResultsPerPageChange,
    };
}
