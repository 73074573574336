import * as React from 'react';
import anime from 'animejs';
import { connect } from 'react-redux';
import { ReduxDispatch } from 'modules/redux-store';
import { SkeletonLoader } from 'main/components';
import CurrencySwitcher from './CurrencySwitcher';
import { CoingeckoThunks, CoingeckoState } from 'modules/coingecko';
import { ApplicationState } from 'modules/redux-store';
import { isEmptyObject, formatCurrency, formatNumber } from 'main/util';

interface DispatchProps {
  getTolarValue: VoidFunction;
}

interface ReduxProps {
  currency: string;
  coingecko: CoingeckoState;
}

interface OwnProps {
  className?: string;
}

type Props = OwnProps & DispatchProps & ReduxProps;

class Summary extends React.Component<Props> {
  mCapRef = React.createRef<HTMLSpanElement>();
  priceRef = React.createRef<HTMLSpanElement>();
  transactionsRef = React.createRef<HTMLSpanElement>();

  animateNumbers = (
    target: string,
    value: number,
    prevValue: number = 0,
    ref?: React.RefObject<any> | null,
    isCurrency?: boolean
  ) => {
    const { currency } = this.props;
    const index = value && value.toString().indexOf('.');

    const prevValueFormatted = isCurrency
      ? formatCurrency(prevValue, currency)
      : formatNumber(prevValue);

    const newValue = isCurrency
      ? formatCurrency(value, currency)
      : formatNumber(value);

    anime({
      targets: '#stats-currency',
      opacity: prevValue ? [1, 0] : [0, 0],
      duration: prevValue ? 200 : 0,
      easing: 'linear',
      complete: () => {
        anime({
          targets: target,
          innerHTML: [prevValueFormatted, newValue],
          duration: 800,
          round: isCurrency
            ? Math.pow(10, (value || 0).toString().length - index - 1)
            : 1,
          easing: 'easeOutQuad',
          complete: () => {
            /**
             * Hack for fixing animejs issue with intl formatting number: Zero after comma is being trimmed. This ensures that the proper value is being displayed after animation runs.
             */
            if (ref && ref.current) ref.current.innerHTML = newValue;
            anime({
              targets: '#stats-currency',
              opacity: [0, 1],
              duration: 200,
              easing: 'linear',
            });
          },
        });
      },
    });
  };

  componentDidMount = () => {
    const { animateNumbers } = this;
    const { getTolarValue, coingecko, currency } = this.props;
    const { transactions, price, market_cap } = coingecko;

    getTolarValue();

    if (!isEmptyObject(price))
      animateNumbers('#stats-price', price[currency], 0, this.priceRef, true);

    if (!isEmptyObject(transactions))
      animateNumbers(
        '#stats-transactions-value',
        transactions[currency],
        0,
        this.transactionsRef
      );

    if (!isEmptyObject(market_cap))
      animateNumbers(
        '#stats-mcap-value',
        market_cap[currency],
        0,
        this.mCapRef
      );
  };

  componentDidUpdate = (prevProps: Props) => {
    const { animateNumbers } = this;
    const { coingecko, currency } = this.props;
    const { price, isLoading, transactions, market_cap } = coingecko;

    const differentCurrencies = prevProps.currency !== currency;

    if (
      differentCurrencies ||
      (prevProps.coingecko.isLoading !== isLoading && !isLoading)
    ) {
      animateNumbers(
        '#stats-price',
        price[currency],
        differentCurrencies ? price[prevProps.currency] : 0,
        this.priceRef,
        true
      );
      animateNumbers(
        '#stats-transactions-value',
        transactions[currency],
        differentCurrencies ? transactions[prevProps.currency] : 0,
        this.transactionsRef
      );

      animateNumbers(
        '#stats-mcap-value',
        market_cap[currency],
        differentCurrencies ? market_cap[prevProps.currency] : 0,
        this.mCapRef
      );
    }
  };

  render() {
    const { className, coingecko } = this.props;
    const { percentage, isLoading, price } = coingecko;
    const percentageClass =
      percentage['btc'] < 0 ? 'color-warning' : 'color-success';

    return (
      <div className={`s-bottom--lrg ${className || ''}`}>
        <h3 className="opacity-50 s-bottom--zero">Tolar Price</h3>
        <div className="s-top--tny s-bottom--med">
          {isLoading ? (
            <SkeletonLoader className="skeletonLoader--lrg s-bottom--sml w-75" />
          ) : (
            <React.Fragment>
              <strong className="t-gamma t-weight-semiBold opacity-90 s-right--sml">
                <span ref={this.priceRef} id="stats-price">
                  0.00
                </span>
              </strong>
              <div id="stats-currency" className="d-inline-block">
                <CurrencySwitcher />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="t-small t-spacing-sml">
          {isLoading ? (
            <SkeletonLoader className="skeletonLoader--med w-50" />
          ) : (
            <React.Fragment>
              <span className="opacity-70 s-right--sml">
                @{price['btc'] && price['btc'].toFixed(10)} BTC
              </span>
              <span className={percentageClass}>
                {percentage['btc'] && `(${percentage['btc'].toFixed(2)}%)`}
              </span>
            </React.Fragment>
          )}
        </div>
        <div className="summary__wrapper">
          {/* <div className="summary__col">
            <h3 className="opacity-50 s-bottom--zero">Transactions</h3>
            {isLoading ? (
              <SkeletonLoader className="skeletonLoader--med w-75" />
            ) : (
              <span
                className="t-small"
                ref={this.transactionsRef}
                id="stats-transactions-value"
              />
            )}
          </div> */}

          <div className="summary__col">
            <h3 className="opacity-50 s-bottom--zero">Market Cap</h3>
            {isLoading ? (
              <SkeletonLoader className="skeletonLoader--med w-75" />
            ) : (
              <span
                className="t-small"
                ref={this.mCapRef}
                id="stats-mcap-value"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
  return { coingecko: state.coingecko, currency: state.settings.currency };
};

const mapDispatchToProps = (dispatch: ReduxDispatch): DispatchProps => ({
  getTolarValue: () => dispatch(CoingeckoThunks.getCurrentValue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
