import { SettingsAction } from './actions';
import { SettingsActionTypes } from './types';
import { currency_USD } from '../constants';

export interface SettingsState {
    currency: string;
}

const INITIAL_STATE: SettingsState = {
    currency: currency_USD.value
};

export const settingsReducer = (
    state: SettingsState = INITIAL_STATE,
    action: SettingsAction
) => {
    switch (action.type) {
        case SettingsActionTypes.Error:
            return {
                ...state,
                ...action.payload
            };
        case SettingsActionTypes.SetSettingsCurrency:
            return {
                ...state,
                ...action.payload
            };

        default:
            return { ...state };
    }
};
