import * as React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const TableBody: React.FC<Props> = ({ children, ...rest }) => (
  <tbody {...rest}>{children}</tbody>
);
