import * as React from 'react';
import {
  ContentWithDate,
  FromTo,
  ShortenedText,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from 'main/components';
import { Link } from 'react-router-dom';
import { Transaction } from 'main/models';
import { Skeleton } from './Skeleton';
import { tolarValue } from 'main/util';

interface Props {
  data: Transaction[];
  isLoading?: boolean;
  error?: string;
}

export const LatestTransactions: React.FC<Props> = ({
  data,
  isLoading,
  error,
}) => {
  const mapData = ({
    hash,
    value,
    transaction_timestamp,
    sender_address,
    receiver_address,
  }: Transaction) => (
    <TableRow key={hash}>
      <TableCell>
        <ContentWithDate date={new Date(parseInt(transaction_timestamp))}>
          <Link
            className="d-block s-top--tny t-small"
            to={`/transaction/${hash}`}
          >
            <ShortenedText text={hash} />
          </Link>
        </ContentWithDate>
      </TableCell>
      <TableCell>
        <FromTo
          className="fromTo--table"
          from={sender_address}
          to={receiver_address}
        />
      </TableCell>
      <TableCell label="Value" className="t-right--tablet">{`Tol ${tolarValue(
        value
      )}`}</TableCell>
    </TableRow>
  );

  const getSkeleton = (_: any, index: number) => (
    <Skeleton key={`latest-transaction-${index}`} />
  );

  return (
    <div>
      <h2 className="s-bottom--zero">
        <strong>Latest</strong> Transactions
      </h2>
      <Table className="table table-responsive--tablet table--align-top t-nowrap">
        <TableBody>
          {!error ? (
            isLoading ? (
              [...Array(5)].map(getSkeleton)
            ) : (
              data.map(mapData)
            )
          ) : (
            <TableRow key="1">
              <TableCell>
                <div>{error}</div>
              </TableCell>
              {/* <TableCell className="t-right">Tol NaN</TableCell> */}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
