import * as React from 'react';

export class Error404 extends React.Component {
  render() {
    return (
      <section className="wrapper">
        <h1>Error 404</h1>
        <p>
          Not found The page you are searching for could not be found. If you
          entered a web address please check if it was correct. Or you can
          always go to <a href="/">homepage</a>.
        </p>
      </section>
    );
  }
}
