import { BigNumber, bigNumberify } from 'ethers/utils';

export const attoTolarToTolar = (attoTolars: BigNumber) => {
    const numberLength = attoTolars.toString().length;
    const numberDivider = 1000000000000000; // only 10^15
    const isSafeInteger = Number.isSafeInteger(Number(attoTolars));

    let numberValue = 0;
    let finalNumber = 0;

    if (numberLength < 18) {
        numberValue = isSafeInteger
            ? Number(attoTolars) / 1000
            : Number(attoTolars.div(new BigNumber(1000)));
        finalNumber = numberValue / numberDivider;
    } else {
        numberValue = Number(attoTolars.div(new BigNumber(1000000)));
        finalNumber = numberValue / (numberDivider / 1000);
    }

    return finalNumber;
};

export const getBuffer = (value: string) =>
    value.length % 2 === 0
        ? Buffer.from(value, 'hex')
        : Buffer.from(`0${value}`, 'hex');

export const bufferToRawNumber = (buffer: Buffer) => bigNumberify(buffer);

export const formatManyDecimals = (value: number) =>
    Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 18,
    }).format(value);

export const tolarValue = (value: string) =>
    value ? formatManyDecimals(attoTolarToTolar(bigNumberify(value))) : 0.0;
