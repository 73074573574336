import { AxiosService } from './AxiosService';
import {
    AddressData,
    Block,
    ContractDetails,
    Transaction,
    VerificationData,
} from 'main/models';
import { QueryResults } from 'main/models/QueryResults';
import { VerificationResponse } from 'main/models/VerificationResponse';

type windowType = typeof window & {
    Configs: { API_ENDPOINT: string; API_CONTRACT_ENDPOINT: string };
};
export class ApiService {
    private baseApiEndpoint = (window as windowType).Configs
        ? (window as windowType).Configs.API_ENDPOINT
        : 'https://explorer.tolar.io/api';

    private contractApiEndpoint = (window as windowType).Configs
        ? (window as windowType).Configs.API_CONTRACT_ENDPOINT
        : 'https://contract-verifier.stagenet.tolar.io';

    private endpoints = {
        addressesEndpoint: `${this.baseApiEndpoint}/addresses`,
        blocksEndpoint: `${this.baseApiEndpoint}/blocks`,
        transactionsEndpoint: `${this.baseApiEndpoint}/transactions`,
        searchEndpoint: `${this.baseApiEndpoint}/`,
        contractEndpoint: `${this.contractApiEndpoint}/contract`,
        bytecodeEndpoint: `${this.baseApiEndpoint}/contract`,
        verifyEndpoint: `${this.contractApiEndpoint}/`,
    };

    private AxiosServiceInstance: AxiosService;

    constructor() {
        this.AxiosServiceInstance = new AxiosService();
    }

    static Instance() {
        return new ApiService();
    }

    async getAddressDetails(addressHash: string): Promise<AddressData | any> {
        // TODO: add parameter check, 50 characters etc. and check [0-9a-fA-F]
        return this.AxiosServiceInstance.getRequest<AddressData>(
            this.endpoints.addressesEndpoint,
            addressHash
        );
    }

    async getBlockDetails(blockHash: string): Promise<Block | any> {
        // TODO: add parameter check, 64 characters etc. and check [0-9a-fA-F] or up to 20 digit number
        return this.AxiosServiceInstance.getRequest<Block>(
            this.endpoints.blocksEndpoint,
            blockHash
        );
    }

    async getLatestBlocks(
        blockCount: number
    ): Promise<{ latest_blocks: Block[] } | string> {
        // TODO: add parameter check, from 1 to 5 max latest blocks
        return this.AxiosServiceInstance.getRequest<{ latest_blocks: Block[] }>(
            this.endpoints.blocksEndpoint,
            `?latest_count=${blockCount}`
        );
    }

    async getTransactionDetails(
        transactionHash: string
    ): Promise<Transaction | any> {
        // TODO: add parameter check, 64 characters etc.
        return this.AxiosServiceInstance.getRequest<Transaction>(
            this.endpoints.transactionsEndpoint,
            transactionHash
        );
    }

    async getLatestTransactions(
        transactionCount: number
    ): Promise<Transaction | any> {
        // TODO: add parameter check, from 1 to 5 max latest transactions
        return this.AxiosServiceInstance.getRequest<Transaction>(
            this.endpoints.transactionsEndpoint,
            `?latest_count=${transactionCount}`
        );
    }

    async getResults(searchString: string): Promise<QueryResults<any>> {
        // TODO: add parameter check, only addresses can be passed
        return this.AxiosServiceInstance.getRequest<QueryResults<any>>(
            this.endpoints.searchEndpoint,
            `?query=${searchString}`
        );
    }

    async getContract(address: string): Promise<ContractDetails> {
        // TODO: add parameter check, only addresses can be passed
        return this.AxiosServiceInstance.getRequest<ContractDetails>(
            `${this.endpoints.contractEndpoint}`,
            `${address}`
        );
    }

    async getBytecode(address: string): Promise<any> {
        // TODO: add parameter check, only addresses can be passed
        return this.AxiosServiceInstance.getRequest<any>(
            `${this.endpoints.bytecodeEndpoint}`,
            `${address}`
        );
    }

    async verifyContract(
        data: VerificationData
    ): Promise<VerificationResponse> {
        // TODO: add parameter check, only addresses can be passed
        return this.AxiosServiceInstance.postRequest(
            `${this.endpoints.verifyEndpoint}verify`,
            data
        );
    }
}
