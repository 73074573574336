import {
    createStore,
    applyMiddleware,
    combineReducers,
    Store,
    AnyAction,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { coingeckoReducer, CoingeckoState } from 'modules/coingecko';
import { settingsReducer, SettingsState } from 'modules/summary';
import { BlockState, blockReducer } from 'modules/block-details';
import {
    TransactionState,
    transactionReducer,
} from 'modules/transaction-details';
import { SearchState, searchReducer } from 'modules';
import { paginationReducer, PaginationState } from 'modules/pagination';

export interface ApplicationState {
    coingecko: CoingeckoState;
    settings: SettingsState;
    block: BlockState;
    transaction: TransactionState;
    search: SearchState;
    pagination: PaginationState;
}

export const configureStore = (): Store<ApplicationState> => {
    /**
     * Create the composing function for our middlewares
     * Include dev tools support
     */
    const composeEnhancers = composeWithDevTools({});

    /**
     * Merge all reducers into a single object
     */
    const rootReducer = {
        coingecko: coingeckoReducer,
        settings: settingsReducer,
        block: blockReducer,
        transaction: transactionReducer,
        search: searchReducer,
        pagination: paginationReducer,
    };

    /**
     * We'll create our store with the combined reducers and all enchancers
     */
    return createStore<ApplicationState, AnyAction, {}, {}>(
        combineReducers(rootReducer),
        {},
        composeEnhancers(applyMiddleware(thunk))
    );
};
