import * as React from 'react';
import { Chart } from 'chart.js';
import anime from 'animejs';
import 'chartjs-plugin-streaming';
import { getRealtimeChartConfig } from './realtimeChartConfig';
import { getStaticChartConfig } from './staticChartConfig';
import { Loader } from 'main/components';
import { GraphData } from '../models';

import { Gradient } from 'main/models';

interface Props {
  getData: (range: number) => void;
  currency: string;
  isRealtime?: boolean;
  data: Array<GraphData>;
  style?: Gradient;
  id?: string;
}

interface State {
  realtimeLoaded: boolean;
}

export class LineGraph extends React.Component<Props, State> {
  state = {
    realtimeLoaded: false
  };

  canvasRef = React.createRef<HTMLCanvasElement>();
  chart: Chart | null = null;

  defaultStyle = {
    colorStart: 'rgba(22,51,69,1)',
    colorEnd: 'rgba(26,29,46,0)',
    yEnd: 200
  };

  getCanvasCtx = () => {
    const canvas = this.canvasRef.current;
    if (!canvas) return null;

    return canvas.getContext('2d');
  };

  createVerticalGradient = (
    colorStart: string,
    colorEnd: string,
    yEnd: number
  ) => {
    const canvasCtx = this.getCanvasCtx();
    const vGradient =
      canvasCtx && canvasCtx.createLinearGradient(0, 0, 0, yEnd);
    if (vGradient) {
      vGradient.addColorStop(0, colorStart);
      vGradient.addColorStop(1, colorEnd);
    }

    return vGradient;
  };

  loadGraphConfig = (data: Array<GraphData>, isRealtime?: boolean) => {
    const { style = this.defaultStyle, currency } = this.props;
    const backgroundColor = this.createVerticalGradient(
      style.colorStart,
      style.colorEnd,
      style.yEnd
    );

    return isRealtime
      ? getRealtimeChartConfig(backgroundColor, currency)
      : getStaticChartConfig(backgroundColor, data);
  };

  initChart = () => {
    const { data, isRealtime } = this.props;
    const canvasCtx = this.getCanvasCtx();
    if (!canvasCtx || !data) return;
    const chartConfig = this.loadGraphConfig(data, isRealtime);
    this.chart = new Chart(canvasCtx, chartConfig);
  };

  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps: Props) {
    const { data, id } = this.props;
    const dataHasChanged =
      this.chart &&
      this.chart.data &&
      this.chart.data.datasets &&
      data !== prevProps.data;

    if (dataHasChanged) {
      anime({
        targets: id ? `#${id}` : '#lineChart',
        opacity: [1, 0],
        duration: 200,
        easing: 'easeInOutQuad',
        complete: () => {
          if (this.chart) {
            this.chart.destroy();
            this.initChart();
            anime({
              targets: id ? `#${id}` : '#lineChart',
              opacity: [0, 1],
              duration: 200,
              easing: 'easeInOutQuad'
            });
          }
        }
      });
    }
  }

  render() {
    const { id, isRealtime } = this.props;

    return (
      <div
        id={`${id ? id : 'lineChart'}`}
        className="fb-100 position-relative s-top--med w-100"
      >
        <canvas ref={this.canvasRef} className="d-block" />
        {isRealtime && <Loader />}
      </div>
    );
  }
}
