import { BlockAction } from './actions';
import { BlockActionTypes } from './types';
import { Block } from 'main/models';

export interface BlockState {
    blocks: Block[];
    selectedBlock?: Block;
    isLoading: boolean;
    error?: string;
}

const INITIAL_STATE: BlockState = {
    blocks: [],
    selectedBlock: undefined,
    isLoading: true,
    error: undefined
};

export const blockReducer = (
    state: BlockState = INITIAL_STATE,
    action: BlockAction
) => {
    switch (action.type) {
        case BlockActionTypes.Request:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case BlockActionTypes.Error:
            return {
                ...state,
                ...action.payload,
                isLoading: false
            };
        case BlockActionTypes.GetLatestBlocks:
            return {
                ...state,
                ...action.payload,
                error: undefined,
                isLoading: false
            };
        case BlockActionTypes.GetBlock:
            return {
                ...state,
                ...action.payload,
                error: undefined,
                isLoading: false
            };
        default:
            return { ...state };
    }
};
