import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Routing } from 'modules';
import { configureStore } from 'modules/redux-store';
// import registerServiceWorker from 'registerServiceWorker';
import 'main/css/app.css';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Routing />
  </Provider>,
  document.getElementById('root') as HTMLElement
);

// registerServiceWorker();
