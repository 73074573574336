import { createAction, ActionUnion } from 'modules/redux-store';
import { SettingsActionTypes } from './types';

const GeneralActions = {
    request: () => createAction(SettingsActionTypes.Request),
    error: (error?: string) =>
        createAction(SettingsActionTypes.Error, { error })
};

const SetActions = {
    setSettingsCurrency: (currency: string) =>
        createAction(SettingsActionTypes.SetSettingsCurrency, { currency })
};

export const SettingsActions = {
    ...GeneralActions,
    ...SetActions
};

export type SettingsAction = ActionUnion<typeof SettingsActions>;
