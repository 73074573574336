enum GeneralActions {
    Request = 'block/REQUEST',
    Error = 'block/ERROR'
}

enum GetActions {
    GetLatestBlocks = 'block/GET_LATEST_BLOCKS',
    GetBlock = 'block/GET_BLOCK'
}

export const BlockActionTypes = {
    ...GeneralActions,
    ...GetActions
};
