import * as React from 'react';
import { SkeletonLoader, TableRow, TableCell } from 'main/components';

export const Skeleton = () => (
  <TableRow>
    <TableCell>
      <SkeletonLoader className="skeletonLoader--med" />
    </TableCell>
    <TableCell>
      <SkeletonLoader className="skeletonLoader--med" />
    </TableCell>
    <TableCell>
      <SkeletonLoader className="skeletonLoader--med" />
    </TableCell>
    <TableCell>
      <SkeletonLoader className="skeletonLoader--med" />
    </TableCell>
    <TableCell>
      <SkeletonLoader className="skeletonLoader--med" />
    </TableCell>
  </TableRow>
);
