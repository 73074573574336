import { createAction, ActionUnion } from 'modules/redux-store';
import { BlockActionTypes } from './types';
import { Block } from 'main/models';

const GeneralActions = {
    request: () => createAction(BlockActionTypes.Request),
    error: (error?: string) => createAction(BlockActionTypes.Error, { error })
};

const GetActions = {
    getLatestBlocks: (blocks: Block[]) =>
        createAction(BlockActionTypes.GetLatestBlocks, { blocks }),
    getBlock: (selectedBlock: Block) =>
        createAction(BlockActionTypes.GetBlock, { selectedBlock })
};

export const BlockActions = {
    ...GeneralActions,
    ...GetActions
};

export type BlockAction = ActionUnion<typeof BlockActions>;
