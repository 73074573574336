import { PaginationAction } from './actions';
import { PaginationActionTypes } from './types';

export interface PaginationState {
    resultsPerPage: number;
}

const INITIAL_STATE: PaginationState = {
    resultsPerPage: 10,
};

export const paginationReducer = (
    state: PaginationState = INITIAL_STATE,
    action: PaginationAction
) => {
    switch (action.type) {
        case PaginationActionTypes.SetResultsPerPage:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return { ...state };
    }
};
