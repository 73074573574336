import * as React from 'react';
import { DataControls } from './DataControls';
import { Dataset } from 'main/models';

interface Props {
  currency: string;
  className: string;
  getData: (range: number) => void;
  children: any;
  dataList: Array<Dataset>;
}

interface State {
  activeDataId: string;
}

export class DataSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeDataId: props.dataList[1].id
    };
  }

  handleDataClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = event.currentTarget;
    this.setState({
      activeDataId: value
    });
  };

  getActiveDataset = () => {
    const { dataList } = this.props;
    const { activeDataId } = this.state;
    const activeDataset = dataList.find(item => item.id === activeDataId);
    if (activeDataset) return activeDataset.days;
    return 0;
  };

  getData = () => this.props.getData(this.getActiveDataset());

  componentDidMount = () => {
    this.getData();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { activeDataId } = this.state;
    const { currency } = this.props;
    if (
      activeDataId !== prevState.activeDataId ||
      currency !== prevProps.currency
    ) {
      this.getData();
    }
  };

  render() {
    const { children, dataList, className } = this.props;
    const { activeDataId } = this.state;

    return (
      <div className={className}>
        <DataControls
          data={dataList}
          activeDataId={activeDataId}
          onClick={this.handleDataClick}
        />
        {children(activeDataId)}
      </div>
    );
  }
}
