export const CHART_DEFAULT_OPTIONS = {
    aspectRatio: 4,
    scales: {
        xAxes: [
            {
                ticks: {
                    display: false
                },
                gridLines: {
                    display: false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    display: false,
                    maxTicksLimit: 3
                },
                gridLines: {
                    color: 'rgba(255,255,255,0.08)',
                    lineWidth: 1,
                    drawBorder: false
                }
            }
        ]
    },
    layout: {
        padding: 0
    },
    tooltips: {
        displayColors: false,
        titleFontSize: 0,
        titleSpacing: 0,
        cornerRadius: 0,
        caretSize: 0,
        caretPadding: 0,
        titleMarginBottom: 0,
        xPadding: 6,
        titleFontFamily: `'Ringside Wide A', 'Ringside Wide B', -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
        'Helvetica Neue', Helvetica, Arial, sans-serif`,
        backgroundColor: 'rgba(43, 47, 52, 0.8)',
        yPadding: 6
    },
    legend: {
        display: false
    },
    title: {
        display: false
    },
    elements: {
        point: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
            hitRadius: 30,
            radius: 0,
            borderWidth: 10
        }
    },
    animation: {
        duration: 0
    },
    hover: {
        animationDuration: 0
    },
    responsiveAnimationDuration: 0,
    plugins: {
        streaming: {
            frameRate: 10
        }
    }
};

export const CHART_STYLE_LINE = {
    borderWidth: 1,
    borderColor: '#01B3D2'
};

export const CHART_TYPE_LINE = 'line';
