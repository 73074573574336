import * as React from 'react';
import { ShortenedText } from 'main/components';

interface Props {
  from: string;
  to: string;
  inline?: boolean;
  className?: string;
  value?: string;
}

export const FromTo: React.FC<Props> = ({
  from,
  to,
  value,
  inline,
  className,
}) =>
  inline ? (
    <div className={`d-flex align-center ${className || ''}`}>
      {value && (
        <span className="t-shorten-wrapper t-tiny">Value: {value}</span>
      )}
      <ShortenedText className="t-tiny" text={from} link />
      <span className="s-left--med s-right--med">
        <span className="chevron chevron--rounded chevron--sml chevron--right" />
      </span>
      <ShortenedText className="t-tiny" text={to} link />
    </div>
  ) : (
    <div className={`d-inline-flex t-tiny ${className || ''}`}>
      <div className="opacity-50">
        <div className="s-right--sml s-bottom--sml fromTo__label">From: </div>
        <div className="s-right--sml fromTo__label">To: </div>
      </div>
      <div className="t-tiny">
        <div className="s-bottom--sml">
          <ShortenedText text={from} link />
        </div>
        <div>
          <ShortenedText text={to} link />
        </div>
      </div>
    </div>
  );
