import { createAction, ActionUnion } from 'modules/redux-store';
import { CoingeckoActionTypes } from './types';
import { PriceData, MarketData } from '../models';

const GeneralActions = {
    request: () => createAction(CoingeckoActionTypes.Request),
    error: (error?: string) =>
        createAction(CoingeckoActionTypes.Error, { error })
};

const GetActions = {
    getTolarChartHistory: (history: PriceData[]) =>
        createAction(CoingeckoActionTypes.GetTolarChartHistory, { history }),
    getTolarValue: (data: MarketData) =>
        createAction(CoingeckoActionTypes.GetTolarValue, data)
};

export const CoingeckoActions = {
    ...GeneralActions,
    ...GetActions
};

export type CoingeckoAction = ActionUnion<typeof CoingeckoActions>;
